import { getPeilv, getService, info } from "@/api";
import { getBalance } from "@/api/wallet";

const getInitialState = () => ({
  token: "",
  userInfo: {
    Account: "",
    AccountType: -1,
    Address: "",
    Amount: 0,
    DeviceId: "",
    DeviceType: "",
    Email: null,
    HeadId: "",
    LockedAmount: 0,
    NickName: "",
    PhoneNum: null,
    RegisterTime: "",
    SellerId: void 0,
    Token: "",
    UserId: null,
    VerifyAddress: "",
    VerifyAmount: 0,
    VerifyState: null,
  },
  service: "",
  personalOdds: null // 个人费率
});

export default {
  namespaced: true,
  state: () => getInitialState(),
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      localStorage.setItem("token", state.token);

    },
    setInfo(state, payload = {}) {
      Object.assign(state.userInfo, payload);

      if (!Reflect.has(payload, "Token")) return;
      this.commit("userStore/setToken", payload.Token);
    },
    setPersonalOdds(state, payload = {}) {
      state.personalOdds = payload
    },
    setBalance(state, payload) {
      state.userInfo.Amount = payload;
    },
    setService(state, payload) {
      state.service = payload;
    },
    resetStore(state) {
      Object.assign(state, getInitialState());
      this.commit("userStore/setToken", "");
    },
  },
  actions: {
    async getUserInfo({ commit }) {
      const data = {
        DeviceId: "",
        DeviceType: "Windows",
        Lang: "zh-CN",
        SellerId: this.userInfo?.SellerId
      };
      return info(data).then(res => {
        commit("setInfo", res.data);
        return res;
      })
    },
    async getUserBalance({ commit }) {
      return getBalance().then(res => {
        commit("setBalance", res.data.Amount);
        return res;
      })
    },
    async getGamePeilv({ commit }) {
      getPeilv().then(res => {
        if (!res.data.GameFee) return;
        commit("setPersonalOdds", JSON.parse(res.data.GameFee));
      })
    },
    async getService({ commit }) {
      return getService({ LangId: 1 }).then(res => {
        commit("setService", res.data.CustomService);
        return res;
      })
    },
  }
};
