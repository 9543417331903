/*
 * @Description: adjust 埋点事件
 * @Author: anji
 * @Date: 2021-11-04 20:46:37
 * @LastEditTime: 2021-11-19 11:25:54
 * @LastEditors: anji
 */
import Adjust from '@adjustcom/adjust-web-sdk'

let url = window.location.href
// let url = "https://clientview1.flea7900.vip/#/?channel=1&adevent=log"
// 正则表达式匹配查询参数
let regex = /[?&]([^=#]+)=([^&#]*)/g;
let params = {};
let match;

while ((match = regex.exec(url)) !== null) {
    let key = match[1];
    let value = match[2];
    params[key] = decodeURIComponent(value);
}

// 获取特定参数的值
let channel = params['channel'];
let adevent = params['adevent'];

const channelEventList = [
    {
        AppToken: '316znn7kpirk',
        EvenTokens: ['o9idlx', '6zpj8f', 'j21nad', '2ndmd0', 'hjjvmx', 'jlwwxd']
        // 注册成功  注册点击  登录成功  登录点击  充值成功 首次充值
    }
]

export const AdjustInitSdk = function () { // adjust初始化
    Adjust.initSdk({
        appToken: channelEventList[channel - 1].AppToken,
        environment: 'sandbox', // production
    });
}

export const IncidentReport = function (index, money) {  // 埋点事件
    let eventToken = channelEventList[channel - 1].EvenTokens[index]
    let data = { eventToken: eventToken }
    if (index == 4) {
        data.revenue = money
        data.currency = 'USD'
    }
    Adjust.trackEvent(data)
    window.AndroidFunction[adevent](eventToken);
}