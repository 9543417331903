import axios from "axios";
import { Toast } from "vant";
import AxiosCanceler from "./helper/axiosCancel";
import checkCode from "./helper/checkCode";
import store from "../store";
import $router from "@/router";

const service = axios.create({
  timeout: 10000,
});

const axiosCanceler = new AxiosCanceler();

service.interceptors.request.use(
  (config) => {
    // 是否取消重复请求，在 api 服务中通过指定的第三个参数: { cancel: false } 来控制
    config.cancel ?? (config.cancel = true);
    config.cancel && axiosCanceler.addPending(config);

    const token = store.state.userStore.token;
    if (token) {
      config.headers["X-Token"] = token;
    } else {
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const { data, config } = response;
    axiosCanceler.removePending(config);
    if (data.code && data?.code !== 200) {
      if (data?.code === 300) {
        axiosCanceler.removeAllPending();
        store.commit("userStore/resetStore");
        $router.push("/login");
      }
      if(data?.data?.errmsg){
        Toast(data?.data?.errmsg);
      }
      return Promise.reject(data?.data);
    }
    return data;
  },
  (error) => {
    let msg = "";
    // 请求超时 && 网络错误单独判断，没有 response
    if (error.message.indexOf("timeout") !== -1) msg = "请求超时！请您稍后重试";
    if (error.message.indexOf("Network Error") !== -1)
      msg = "网络错误！请您稍后重试";
    // 根据服务器响应的错误状态码，做不同的处理
    // if (response) msg = checkCode(response.status);
    msg && Toast(msg);
    return Promise.reject(error);
  }
);

export default service;
