/*
 * @Description: 页面跳转
 * @Author: anji
 * @Date: 2021-11-16 14:44:45
 * @LastEditTime: 2021-11-18 18:10:27
 * @LastEditors: anji
 */
import router from '../../router/index'

const walletLangConfig = [
  { zh: 'zh-cn', en: 'en-us', th: 'en-us', ko: 'ko', tr: 'en-us', vi: 'en-us', pt: 'en-us' },
  { zh: 'zh', en: 'en', th: 'th', ko: 'ko', tr: 'en', vi: 'vi', pt: 'pt' },
  { zh: 'cn/', en: '', th: '', ko: '', tr: '', vi: '', pt: '' },

  { zh: 'zh-TW', en: '', th: '', ko: 'ko', tr: 'tr', vi: 'vi', pt: 'pt-PT' },

  { zh: '', en: '', th: '', ko: '', tr: '', vi: '', pt: '' },
  { zh: '', en: 'en/', th: 'en/', ko: 'en/', tr: 'en/', vi: 'en/', pt: 'en/' },
]

export const jumpTo = function (path) {
  if (path === 'back') {
    router.back()
  } else {
    router.push({ path: path }).catch(() => {})
  }
}

export const windowOPen = function (type, url) {
  // 1.外部链接，2.当前区块信息，3.交易哈希信息，4.钱包地址信息
  if (type === 1) {
    window.open(url, '_blank')
  } else if (type === 2) {
    window.open('https://tronscan.org/#/block/' + url, '_blank')
  } else if (type === 3) {
    window.open('https://tronscan.org/#/transaction/' + url, '_blank')
  } else {
    window.open('https://tronscan.org/#/address/' + url, '_blank')
  }
}

export const walletDownLoad = function (index, lang) {
  switch (index) {
    case 0:
      return window.open(`https://token.im/download?locale=${walletLangConfig[index][lang]}`, '_blank')
    case 1:
      return window.open(`https://www.tokenpocket.pro/${walletLangConfig[index][lang]}/download/app`, '_blank')
    case 2:
      return window.open(`https://www.tronlink.org/${walletLangConfig[index][lang]}dlDetails/`, '_blank')
    case 3:
      return window.open(`https://trustwallet.com/${walletLangConfig[index][lang]}`, '_blank')
    case 4:
      return window.open(`https://bitpie.com/`, '_blank')
    case 5:
      return window.open(`https://ownbit.io/${walletLangConfig[index][lang]}download/`, '_blank')
  }
}

export const jumpToIFrame = function (GameId, Brand) {
  if (router.currentRoute.query.id == GameId && router.currentRoute.query.type == Brand) {
    return
  }
  if (router.currentRoute.path == '/iFrame') {
    router.replace({
      path: '/iFrame',
      query: { id: GameId, type: Brand },
    })
  } else {
    router.push({
      path: '/iFrame',
      query: { id: GameId, type: Brand },
    })
  }
}
