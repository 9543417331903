import { post, service } from '@/core/request'

// 获取站点配置信息列表
export const appInfo = (params) => post(`${service.app}/info`, params)

// 短信
export const sendSms = (params) => post(`${service.app}/send_sms`, params)

// 提现配置
export const withdrawConfig = (params) => post(`${service.app}/recharge_config`, params, { loading: false })

// 获取公告
export const Annmt = (params) => post(`notice/get`, params)

// 排行榜
export const rankData = (params) => post(`rank/data`, params, { loading: false })

//接入聊天室api
export const chatLogin = (params) => post(`chat/login`, params)

//兑换信息
export const exchangeInfo = (params) => post(`duihuan/info`, params)

//兑换 预算
export const exchangeCalc = (params) => post(`duihuan/calc`, params, { loading: false })

//充值埋点接口
export const rechargeBuryPoint = (params) => post(`user/recharge_maidian`, params)
//邮箱发送验证码
export const sendEmail = (params) => post(`app/send_email`, params, { loading: false })
// 绑定邮箱
export const bindEmail = (params) => post(`/user/bind_email`, params, { loading: false })
