/*
 * @Description: 辅助函数
 * @Author: anji
 * @Date: 2021-11-20 13:02:40
 * @LastEditTime: 2021-11-21 00:19:08
 * @LastEditors: anji
 */
import axios from 'axios'
import Qs from 'qs'
import { Toast } from 'vant' // vant的toast提示框组件，大家可根据自己的ui组件更改。
import router from '@/router'
import store from '@/store'
import utils from '@/utils'
import i18n from '@/lang'
const pendingRequest = new Map()

export function generateReqKey(config) {
  const { method, url, params, data } = config
  return [method, url, Qs.stringify(params), Qs.stringify(data)].join('&')
}

export function addPendingRequest(config) {
  const requestKey = generateReqKey(config)
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel)
      }
    })
}

export function removePendingRequest(config) {
  const requestKey = generateReqKey(config)
  if (pendingRequest.has(requestKey)) {
    const cancel = pendingRequest.get(requestKey)
    cancel(requestKey)
    pendingRequest.delete(requestKey)
  }
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
export const toLogin = () => {
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath,
    },
  })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
export const errorHandle = (status, msg) => {
  if (msg.errcode == 1000000) {
    //特殊处理，三方的操作频繁不显示
    console.log('error:', msg.errmsg)
  } else if (status == 100 || status == 200) {
    if (msg.errcode != -5555 && msg.errcode != 10000) {
      // 签到活动不满足领取条件弹窗特殊处理
      var res = utils.matchResMsg(msg.errmsg)
      if (res) utils.fail(i18n.t(res))
      else utils.fail(msg.errmsg)
    }
  } else {
    if (msg.errcode == 2) {
      utils.logoutRemoveItem()
      store.commit('updateIsLogin', false)
      setTimeout(() => {
        toLogin()
      }, 1000)
    }
    var res = utils.matchResMsg(msg.errmsg)
    if (res) utils.fail(i18n.t(res))
    else utils.fail(msg.errmsg)
  }
}

/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
  // console.log(pendingRequest, 'pendingRequest')
  for (const [url, cancel] of pendingRequest) {
    cancel(url)
  }
  pendingRequest.clear()
}
