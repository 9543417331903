/*
 * @Description: 三方游戏登录参数配置
 * @Author: anji
 * @Date: 2021-11-04 20:46:37
 * @LastEditTime: 2021-11-19 11:25:54
 * @LastEditors: anji
 */
import store from '@/store'
import utils from 'rutilsjs'
const LangConfig = {
  // 多语言配置
  gfg: { zh: 'zh_cn', en: 'en_us', th: 'th_th', tr: 'en_us', ko: 'ko_kr', vi: 'vi_vn', pt: 'en_us', 'zh-TW': 'zh_cn' },
  pg: { zh: 'zh_cn', en: 'en_us', th: 'th_th', tr: 'en_us', ko: 'ko_kr', vi: 'vi_vn', pt: 'en_us', 'zh-TW': 'zh_cn' },
  pp: { zh: 'zh', en: 'en', th: 'th', tr: 'tr', ko: 'ko', vi: 'vi', pt: 'pt', 'zh-TW': 'zh' },
  hub88: { zh: 'zh', en: 'en', th: 'th', tr: 'tr', ko: 'ko', vi: 'vi', pt: 'pt', 'zh-TW': 'zh' },
  evo: { zh: 'CN', en: 'US', th: 'TH', tr: 'TR', ko: 'KR', vi: 'VN', pt: 'BR', 'zh-TW': 'CN' },
  wm: { zh: 0, en: 1, th: 2, tr: 1, ko: 5, vi: 3, pt: 1, 'zh-TW': 0 },
  astar: {
    zh: 'zh-cn',
    en: 'en-us',
    th: 'th-th',
    tr: 'en-us',
    ko: 'ko-kr',
    vi: 'vi-vn',
    pt: 'pt-br',
    'zh-TW': 'zh-tw',
  },
  ag: { zh: '1', en: '3', th: '6', tr: '3', ko: '5', vi: '8', pt: '23', 'zh-TW': '1' },
  up: { zh: 'zh-CHS', en: 'en-US', tr: 'tr-TR', ko: 'ko-KR', pt: 'pt-PT', ru: 'ru-RU', ja: 'ja-JP', 'zh-TW': 'zh-CHS' },
  easybet: {
    zh: 'zh_CN',
    en: 'en_US',
    th: 'en_US',
    tr: 'en_US',
    ko: 'en_US',
    vi: 'vi-VN',
    pt: 'pt_PT',
    'zh-TW': 'zh_CN',
  },
  three_up: { zh: '2', en: '1', th: '5', tr: '1', ko: '1', vi: '6', pt: '9', 'zh-TW': '2' },
  xyx: { zh: 'zh-CN', en: 'en-US', th: 'th-TH', tr: 'en-US', ko: 'ko-KR', vi: 'vi-VN', pt: 'en-US', 'zh-TW': 'zh-CN' },
  lottery: {
    zh: 'zh-CN',
    en: 'en-US',
    th: 'th-TH',
    tr: 'en-US',
    ko: 'ko-KR',
    vi: 'vi-VN',
    pt: 'pt-PT',
    'zh-TW': 'zh-CN',
  },
  chess: {
    zh: 'zh_cn',
    en: 'en_us',
    th: 'th_th',
    tr: 'en_us',
    ko: 'ko_kr',
    vi: 'vi_vn',
    pt: 'en_us',
    'zh-TW': 'zh_cn',
  },
  idn: { zh: 'cs', en: 'en', th: 'th', tr: 'en', ko: 'kr', vi: 'vn', pt: 'en', ja: 'jp', 'zh-TW': 'cs' },
  spribe: {
    zh: 'cn',
    en: 'en',
    th: 'th',
    tr: 'en',
    ko: 'kor',
    vi: 'vn',
    pt: 'pt',
    ja: 'jpn',
    hi: 'en',
    ru: 'rus',
    km: 'en',
    'zh-TW': 'cn',
  },
  og: {
    zh: 'zh_cn',
    en: 'en_us',
    th: 'th_th',
    tr: 'en_us',
    ko: 'ko_kr',
    vi: 'vi_vn',
    pt: 'pt_pt',
    ja: 'ja_jp',
    hi: 'en_in',
    ru: 'en_us',
    km: 'en_us',
    'zh-TW': 'zh_cn',
  },
  og35: { zh: 'zh_cn', en: 'en_us', 'zh-TW': 'zh_cn' },
  og29: { zh: 'zh_cn', en: 'en_us', 'zh-TW': 'zh_cn' },
}

const LangVoice = {
  // 声音参数配置
  wm: { zh: 'cn', en: 'en', th: 'th', tr: 'en', ko: 'ko', vi: 'vi', pt: 'en', 'zh-TW': 'cn' },
}

const symbolList = {
  brl: 'R$',
  sgd: 'S$',
  hkd: 'HK$',
  myr: 'RM',
  thb: '฿',
  php: '₱',
  idr: 'Rp',
  inr: '₹',
  krw: '₩',
  jpy: 'JP¥',
  mxn: '$',
}

export const thirdParams = function (name, type) {
  // name: 三方GameId， type: 游戏类型
  let Lang = localStorage.getItem('lang') || 'zh'
  let obj = {}
  let navLang = localStorage.getItem('lang') || store.state.Language
  let langCon = ''
  if (type == 'og' && (name == '35' || name == '29')) {
    langCon = LangConfig[type + name][navLang] ? LangConfig[type + name][navLang] : LangConfig[type + name]['en']
  } else {
    if (type.indexOf('hub88') != -1) {
      langCon = LangConfig['hub88'][navLang] ? LangConfig['hub88'][navLang] : LangConfig['hub88']['en']
    } else {
      langCon = LangConfig[type][navLang] ? LangConfig[type][navLang] : LangConfig[type]['en']
    }
  }
  if (type == 'xyx') {
    obj.LangCode = langCon
    obj.GameCode = name
  } else if (type == 'spribe') {
    obj.Lang = langCon
    obj.GType = 22
    obj.MType = Number(name)
  } else if (type == 'og') {
    obj.Lang = langCon
    obj.GameId = Number(name)
    obj.Platform = 1
    obj.avatar = store.state.app.appInfo.ImageUrl + `/avatar/avatar${store.state.user.userInfo.HeadId || 1}.webp`
  } else if (type == 'lottery' || type == 'chess' || type == 'gfg') {
    obj.LangCode = langCon
    obj.GameId = Number(name)
  } else if (type == 'pp' || type == 'pg') {
    obj.Lang = langCon
    obj.GameId = name + ''
  } else if (type.indexOf('hub88') != -1) {
    obj.Lang = langCon
    obj.GameCode = name + ''
    obj.LobbyUrl = window.location.href
    obj.DepositUrl = window.location.href.replace('home', 'newDeposit')
  } else if (name == 'ag') {
    obj.Lang = langCon
    obj.GameId = '0'
  } else if (name == 'wm') {
    obj.Lang = langCon
    obj.Voice = LangVoice[name][Lang]
  } else if (name == 'astar') {
    obj.language = langCon
    obj.gameType = 1
  } else if (name == 'easybet') {
    obj.lang = langCon
  } else if (name == 'three_up') {
    obj.Lang = langCon
    obj.Platform = utils.isMobile() ? '1' : '2'
    obj.Lobby = window.location.href
  } else if (name == 'idn') {
    obj.GameId = 'TXH'
    obj.Lang = langCon
  } else {
    obj.Lang = langCon
  }
  return obj
}

export const getSymbolValue = function (symbol) {
  var data = symbolList[symbol]
  if (data) {
    return data
  }
  return ''
}
