import Vue from "vue";
import date from "rdatejs";
import rutils from "rutilsjs";
import mdate from 'moment'

const files = require.context("./modules", true, /\.js$/);
const extend = files
  .keys()
  .reduce(
    (p, c) => ({
      ...p,
      ...Object.keys(files(c)).reduce(
        (pre, nxt) => ({ ...pre, [nxt]: files(c)[nxt] }),
        {}
      ),
    }),
    {}
  );
const utils = {
  mdate,
  date,
  ...rutils,
  ...extend, // 业务扩展工具方法
};
Vue.prototype.$utils = utils;
Object.defineProperty(window, "util", {
  get(val) {
    return Vue.prototype.$utils;
  },
});

export default utils;
