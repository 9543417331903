/*
 * @Description: 百度流量统计代码
 * @Author: anji
 * @Date: 2021-11-04 20:46:37
 * @LastEditTime: 2021-11-19 11:25:54
 * @LastEditors: anji
 */

const domainNameList = [
  {
    url: 'okb99.com',
    code: '898f681e9cbc437aedb52f5e2d884b3b',
  },
  {
    url: 'okb22.com',
    code: 'c74b98563a30a828e68e62dcfc82ead0',
  },
  {
    url: 'okb789.com',
    code: 'addab01fe336a602521836b28590c6df',
  },
  {
    url: 'okb01.com',
    code: 'aadeea469c846b93b0a8df5cd16d62da',
  },
  {
    url: 'okb567.com',
    code: '0b6d0a681cf724a39e25c347f5e82da4',
  },
  {
    url: 'okb33.com',
    code: '1e9aa4856d6afe4c584c70ccfb77f8a8',
  },
  {
    url: 'okhash2.com',
    code: 'd67a7dda8ffb34f4d4656a34cab5a154',
  },
  {
    url: 'okhash3.com',
    code: '45280288062ec5335bceffa971d94531',
  },
  {
    url: 'okhash4.com',
    code: '9a8c11d10b4d2c93682a8c62c2d4f426',
  },
  {
    url: 'okhash5.com',
    code: 'ffbb13e4bd2d386ed39e7a653da50590',
  },
  {
    url: 'okhash6.com',
    code: '371108fd3d8b1608e30c9fa949d578ab',
  },
  {
    url: 'okhash7.com',
    code: 'd6ed90a45c41e1fdac1d76ae208e3fee',
  },
  {
    url: 'okhash8.com',
    code: '933e23dcc91c5383dfe51e476f6625c2',
  },
  {
    url: 'okhash9.com',
    code: '0381f5fbcadea7503431bcaa2615c346',
  },
  {
    url: 'okhash10.com',
    code: 'b2e73686a3c0c7551d1e124193bf61b5',
  },
  {
    url: 'okhash11.com',
    code: 'f2f6695dd583f216424bdf03e8b6e06c',
  },
  {
    url: 'okhash12.com',
    code: '88603c67f76e1840adae71d25f7f1791',
  },
  {
    url: 'okhash13.com',
    code: '3229f53bae1111e4afb6db019e0c5536',
  },
  {
    url: 'okhash14.com',
    code: '1478a572fb9c666b1ce8125014496499',
  },
  {
    url: 'okhash16.com',
    code: 'd9504798ce59f992ad8e3eeda2a533d4',
  },
  {
    url: 'okhash17.com',
    code: 'f5989b076db9f4e924a970eebdd2afbc',
  },
  {
    url: 'okhash18.com',
    code: '86641f6152dc2f6a65f713be7f176516',
  },
  {
    url: 'okhash19.com',
    code: '82d91cc11384ba8967258d0a26c0d11f',
  },
  {
    url: 'okhash26.com',
    code: '529fe7797f5e16c8d74cdeb38e62bc06',
  },
  {
    url: '98hx.com',
    code: '4752b74c26b301f725ba844704d550eb',
  },
  {
    url: '98gg01.online',
    code: '9bea53a7205a9ede9a5d8dfe64adc8ee',
  },
  {
    url: '98gg02.online',
    code: '602406eb7e292bec28898264832b02a5',
  },
  {
    url: '98gg05.online',
    code: 'b9526dcf2ba0b9357d17b8dc3c7125cf',
  },
  {
    url: '98gg06.online',
    code: '70df5c706c26b0f29447071a014794d1',
  },
  {
    url: '98gg09.online',
    code: 'd2a4e410493099d01f1765503e04e9dd',
  },
  {
    url: '98gg10.online',
    code: 'be1d26027482d0752f2ad2c8923ac43c',
  },
  {
    url: '98gg12.online',
    code: '17dd9aff00909e96a88ea5bd883993d4',
  },
  {
    url: '98gg16.online',
    code: '2e33f7b0f4f2cfd5491df548d87c2f56',
  },
  {
    url: '98gg17.online',
    code: 'a4fb1e9c6e102bf8b1a4d4b6c5cdf731',
  },
  {
    url: '98gg19.online',
    code: '812139c606c8933b65fa395b20102ef6',
  },
]

const domainList = [
  {
    url: '98gg02.online',
    code: 'KHRZ9KSxkq7a1yU1',
  },
  {
    url: '98gg04.online',
    code: 'KHRwH75P9xfiBgib',
  },
  {
    url: '98gg05.online',
    code: 'KHS4gzzJL8ZopkyV',
  },
  {
    url: '98gg06.online',
    code: 'KHS4oCxreHv3FpP5',
  },
  {
    url: '98gg09.online',
    code: 'KHS4wjuBwfXlBlEQ',
  },
  {
    url: '98gg10.online',
    code: 'KHS50WLaw9v1zkpD',
  },
  {
    url: '98gg11.online',
    code: 'KHS53i8Rff5RVwnZ',
  },
  {
    url: '98gg12.online',
    code: 'KHS571f7K2xRmdlW',
  },
  {
    url: '98gg13.online',
    code: 'KHS5AKi0mKdAtfio',
  },
  {
    url: '98gg14.online',
    code: 'KHS5DGyVqF5gQH1X',
  },
  {
    url: 'ok8.game',
    code: 'KOrMMUGXXslsfRFx',
  },
  {
    url: '9ok.one',
    code: 'KOrMPI9I6xTuOA9U',
  },
  {
    url: 'okhash1.com',
    code: 'Jps4vM4rCVrZ4hIa',
  },
  {
    url: 'okhash2.com',
    code: 'KJdQgg5tqgh9mHOU',
  },
  {
    url: 'okhash3.com',
    code: 'KOrKK2OkwJjPEQ9N',
  },
  {
    url: 'okhash4.com',
    code: 'KOrKP58XhXzOIoMa',
  },
  {
    url: 'okhash5.com',
    code: 'JjoCXsp2nL5Oaqfe',
  },
  {
    url: 'okhash6.com',
    code: 'JqZB7NUnHxvjAQ5B',
  },
  {
    url: 'okhash7.com',
    code: 'Ji5gnhTb4QRwUc0H',
  },
  {
    url: 'okhash8.com',
    code: 'KJdQpsCHSil6yZzA',
  },
  {
    url: 'okhash9.com',
    code: 'JggXD22LFgVoas5R',
  },
  {
    url: 'okhash10.com',
    code: 'JyoCYRBt2QJYYk2F',
  },
  {
    url: 'okhash11.com',
    code: 'KHRwH75P9xfiBgib',
  },
  {
    url: 'okhash12.com',
    code: 'KHS50WLaw9v1zkpD',
  },
  {
    url: 'okhash13.com',
    code: 'JggcIuq3dPjj8jgu',
  },
  {
    url: 'okhash14.com',
    code: 'KHS4wjuBwfXlBlEQ',
  },
  {
    url: 'okhash15.com',
    code: 'JggblBEPfp9ZlYh7',
  },
  {
    url: 'okhash16.com',
    code: 'KHS571f7K2xRmdlW',
  },
  {
    url: 'okhash17.com',
    code: 'KHS53i8Rff5RVwnZ',
  },
  {
    url: 'okhash18.com',
    code: 'JyoCyPdNA7D0C2B6',
  },
  {
    url: 'okhash19.com',
    code: 'JiamKsUBA8ViAcgE',
  },
  {
    url: 'okhash20.com',
    code: 'KHS4oCxreHv3FpP5',
  },
  {
    url: 'okhash21.com',
    code: 'KHS4gzzJL8ZopkyV',
  },
  {
    url: 'okhash22.com',
    code: 'KHRZ9KSxkq7a1yU1',
  },
  {
    url: 'okhash23.com',
    code: 'KHS5DGyVqF5gQH1X',
  },
  {
    url: 'okhash24.com',
    code: 'KHS5AKi0mKdAtfio',
  },
  {
    url: 'okhash26.com',
    code: 'JlHGKahNpgFBoU5H',
  },
  {
    url: 'okhash27.com',
    code: 'JndNtnYsmdzArRAF',
  },
  {
    url: 'okhash28.com',
    code: 'JgkHMn5nEApgvtMc',
  },
  {
    url: 'okhash29.com',
    code: 'JkzxEUXxE1HsAxT5',
  },
  {
    url: 'okhash30.com',
    code: 'JgkHGcU51rjnEcTe',
  },
  {
    url: 'okhash31.com',
    code: 'Jj6iKo3OakRJqXuQ',
  },
  {
    url: 'okhash32.com',
    code: 'KOrKZN928QpJv4Eb',
  },
  {
    url: 'okhash33.com',
    code: 'KOrKcQqPGQx7pvYI',
  },
  {
    url: 'okhash34.com',
    code: 'KOrKg3xBKLXfplqP',
  },
  {
    url: 'okhash35.com',
    code: 'KOrKjeXcrjboVh0Q',
  },
  {
    url: 'okhash36.com',
    code: 'KOrKmWHGx4BYmJ99',
  },
  {
    url: 'okhash37.com',
    code: 'KOrKqKDOK8lrgTsV',
  },
  {
    url: 'okhash38.com',
    code: 'KOrKvUDtlbrvaiJg',
  },
  {
    url: 'okhash39.com',
    code: 'KOrKyebRnBPkFrw2',
  },
  {
    url: 'okhash40.com',
    code: 'KOrL2vCjRr5jKw1N',
  },
  {
    url: 'okhash41.com',
    code: 'KOrL5d1smOl0rLlS',
  },
  {
    url: 'okhash42.com',
    code: 'KOrL89opa6JCjFOd',
  },
  {
    url: 'okhash43.com',
    code: 'KOrLCipo2QBl06WX',
  },
  {
    url: 'okhash44.com',
    code: 'KOrLFn517GRHqrKG',
  },
  {
    url: 'okhash45.com',
    code: 'KOrLIimKJQ7VpKo9',
  },
  {
    url: 'okhash1.live',
    code: 'KOrMSHDidGpigOix',
  },
  {
    url: 'okhash2.live',
    code: 'KOrMVN3jioB1ybCO',
  },
  {
    url: 'okhash3.live',
    code: 'KOrMYurbihzQQdDE',
  },
  {
    url: 'okhash4.live',
    code: 'KOrMbdwaKml1VB5s',
  },
  {
    url: 'okhash5.live',
    code: 'KOrMf3qU1x1Vu8Qd',
  },
  {
    url: 'okhash6.live',
    code: 'KOrMiA29o6hxgwes',
  },
  {
    url: 'okhash7.live',
    code: 'KOrMkhu6Y2BswyGe',
  },
  {
    url: 'okhash8.live',
    code: 'KOrMnWhhfTzBYQRB',
  },
  {
    url: 'okhash9.live',
    code: 'KOrMqOtm2074jjwg',
  },
  {
    url: 'okhash.buzz',
    code: 'KOrMt32rBb55HvNn',
  },
  {
    url: '98hash.net',
    code: 'KOrNcipRdrr3OEGw',
  },
  {
    url: '98hash.cn',
    code: 'KOrNWkwpcCt6AatJ',
  },
  {
    url: '98hash.co',
    code: 'KOrNZmRV1vr9OMe2',
  },
  {
    url: '98hash.org',
    code: 'KOrNU2P0zVvC8INI',
  },
  {
    url: 'okhash48.com',
    code: 'KQJyNmQo3AteBy2U',
  },
  {
    url: 'okhash49.com',
    code: 'KQJyvvfThMtlLQ6U',
  },
  {
    url: 'okhash50.com',
    code: 'KQJz22E77CJdUwyQ',
  },
  {
    url: 'okhash51.com',
    code: 'KQJz6x4bX9L1mYWc',
  },
  {
    url: 'okhash52.com',
    code: 'KQK7fSbQdhvRguVs',
  },
  {
    url: 'okhash53.com',
    code: 'KQK7nD2FDzzNll9v',
  },
  {
    url: 'okhash54.com',
    code: 'KQK7uNs9Zc7Rov8J',
  },
  {
    url: 'okhash55.com',
    code: 'KQK80Dca9wl99GA0',
  },
  {
    url: 'okhash56.com',
    code: 'KQK8KkcmLdPJhGIf',
  },
  {
    url: 'okhash57.com',
    code: 'KQK8PcNkNJ923Twr',
  },
  {
    url: 'okhash58.com',
    code: 'KQK8VT9n6a79UNeB',
  },
  {
    url: 'okhash59.com',
    code: 'KQK8n7rpIdj4vAcK',
  },
  {
    url: 'okhash60.com',
    code: 'KQK8vKHqiCtjsb6T',
  },
  {
    url: 'okhash61.com',
    code: 'KQV2RlsEmUhaItpM',
  },
  {
    url: 'okhash62.com',
    code: 'KQK930jeGlDjemHF',
  },
  {
    url: 'okhash63.com',
    code: 'KQK995J79Rv7hKHL',
  },
  {
    url: 'okhash64.com',
    code: 'KQK9Ff6mg5X2giKw',
  },
  {
    url: 'okhash65.com',
    code: 'KQK9KdnUeq73sz1q',
  },
  {
    url: 'okhash66.com',
    code: 'KQK9PKG18hzRePdM',
  },
  {
    url: 'okhash67.com',
    code: 'KQK9UjjUSUBAoNES',
  },
  {
    url: 'okhash68.com',
    code: 'KQK9bADsySdBEw2t',
  },
  {
    url: 'okhash69.com',
    code: 'KQK9fLgPwyNWInPM',
  },
  {
    url: 'okhash70.com',
    code: 'KQK9lx3XZORYciWb',
  },
  {
    url: 'okhash71.com',
    code: 'KQMzUrTGTBnsPX1S',
  },
  {
    url: 'okhash72.com',
    code: 'KQMzaJpOxZ9GLo1f',
  },
  {
    url: 'okhash73.com',
    code: 'KQMzileKUhT20WUy',
  },
  {
    url: 'okhash74.com',
    code: 'KQN4zhdU1fjfUlmD',
  },
  {
    url: 'okhash75.com',
    code: 'KQN5uXXvXihcwprR',
  },
  {
    url: 'okhash76.com',
    code: 'KQN63Acampboqi7y',
  },
  {
    url: 'okhash77.com',
    code: 'KQN6DkkV81v32lA0',
  },
  {
    url: 'okhash78.com',
    code: 'KQN6JwHjqARpp8C3',
  },
  {
    url: 'okhash79.com',
    code: 'KQN6PhH6JKRXCkRT',
  },
  {
    url: 'okhash80.com',
    code: 'KQN6VIxrjHbZo55i',
  },
  {
    url: 'okhash81.com',
    code: 'KQN6boIk25H9VaBZ',
  },
  {
    url: 'okhash82.com',
    code: 'KQN6i8ktERLtnZNA',
  },
  {
    url: 'okhash83.com',
    code: 'KQN6oIwD1vrjQ4yv',
  },
  {
    url: 'okhash84.com',
    code: 'KQN6u9DnbeJIcoug',
  },
  {
    url: 'okhash85.com',
    code: 'KQN6zC1e7cVaBS6W',
  },
  {
    url: 'okhash86.com',
    code: 'KQN74epWvj5c5NPx',
  },
  {
    url: 'okhash87.com',
    code: 'KQN7AcpaVPbF7HSd',
  },
  {
    url: 'okhash88.com',
    code: 'KQN7I5FklxP2NXVv',
  },
  {
    url: 'okhash89.com',
    code: 'KQN7NcC82CFS4oCy',
  },
  {
    url: 'okhash90.com',
    code: 'KQN7SIZunrr8bZkP',
  },
  {
    url: 'okhash91.com',
    code: 'KQN7WiigbnTej7cs',
  },
  {
    url: 'okhash92.com',
    code: 'KQN7bXM6MoxCwFk8',
  },
  {
    url: 'okhash93.com',
    code: 'KQN7gbuuHL5b22AB',
  },
  {
    url: 'okhash94.com',
    code: 'KQN7ltYT37fWHtyF',
  },
  {
    url: 'okhash95.com',
    code: 'KQN7q4rWbEtSMUfs',
  },
  {
    url: 'okhash96.com',
    code: 'KQN7vhfJpmx7unCN',
  },
  {
    url: 'okhash97.com',
    code: 'KQN80CxWHKpdeAw8',
  },
  {
    url: 'okhash98.com',
    code: 'KQN84m0Wc1fmez08',
  },
  {
    url: 'okhash99.com',
    code: 'KQN89lCMhn5xVMeP',
  },
  {
    url: 'okhash100.com',
    code: 'KQN8EeDpySFQikHm',
  },
  {
    url: 'okhash101.com',
    code: 'KQN9ksjmQ2BOb6Cm',
  },
  {
    url: 'okhash102.com',
    code: 'KQN9q3lE2zH7JAPI',
  },
  {
    url: 'okhash103.com',
    code: 'KQN9wIVQhQd3qrQN',
  },
  {
    url: 'okhash104.com',
    code: 'KQNAIPtv8r1Y0utc',
  },
  {
    url: 'okhash105.com',
    code: 'KQNAMm73iKVFE6rw',
  },
  {
    url: 'okhash106.com',
    code: 'KQNAS7ARe63DIk1L',
  },
  {
    url: 'okhash107.com',
    code: 'KQNAW5M2V7X18pCM',
  },
  {
    url: 'okhash108.com',
    code: 'KQNAnBhdp7f8CWxP',
  },
  {
    url: 'okhash109.com',
    code: 'KQNArkC8FW3LsUdk',
  },
  {
    url: 'okhash110.com',
    code: 'KQNAw2PZpnT1usAj',
  },
  {
    url: 'okhash111.com',
    code: 'KQNB0IPdkLPdG8p1',
  },
  {
    url: 'okhash112.com',
    code: 'KQNB4B2lTqh4bwAp',
  },
  {
    url: 'okhash113.com',
    code: 'KQNB8Mteyo3ulida',
  },
  {
    url: 'okhash114.com',
    code: 'KQNBC2n0aCtlKGzU',
  },
  {
    url: 'okhash115.com',
    code: 'KQNJPsEZd0R4K8jq',
  },
  {
    url: 'okhash116.com',
    code: 'KQNJUnsT3J1S0GbU',
  },
  {
    url: 'okhash117.com',
    code: 'KQNJqDR6E9vZ9pM5',
  },
  {
    url: 'okhash118.com',
    code: 'KQNJwMmJBTbQuFL3',
  },
  {
    url: 'okhash119.com',
    code: 'KQNK13un7fTYaqHA',
  },
  {
    url: 'okhash120.com',
    code: 'KQNK6AHGGYpSIW8N',
  },
  {
    url: 'okhash121.com',
    code: 'KQNKXE1dSylG25eW',
  },
  {
    url: 'okhash122.com',
    code: 'KQNLBngdsPHJElSj',
  },
  {
    url: 'okhash123.com',
    code: 'KQNLI6rbsJbA3nHn',
  },
  {
    url: 'okhash124.com',
    code: 'KQNLMV5wrIl3d71Y',
  },
  {
    url: 'okhash125.com',
    code: 'KQNLRM3VscxEufe6',
  },
  {
    url: 'okhash126.com',
    code: 'KQNLVYI5wZb8ooRw',
  },
  {
    url: 'okhash127.com',
    code: 'KQNLZeBTq6pNkiie',
  },
  {
    url: 'okhash128.com',
    code: 'KQNLeZLb4JDPwvgH',
  },
  {
    url: 'okhash129.com',
    code: 'KQNLjEy0i0FxmcNq',
  },
  {
    url: 'okhash130.com',
    code: 'KQNLnPULDEFxGkSa',
  },
  {
    url: 'okhash131.com',
    code: 'KQNLrwgIWGFW9Y9M',
  },
  {
    url: 'okhash132.com',
    code: 'KQNLwIqjFuRX4GND',
  },
  {
    url: 'okhash133.com',
    code: 'KQNM1CNKdaRISxG5',
  },
  {
    url: 'okhash134.com',
    code: 'KQNM6Wo8uJbtXqPQ',
  },
  {
    url: 'okhash135.com',
    code: 'KQNMAiJNiedx6Xnx',
  },
  {
    url: 'okhash136.com',
    code: 'KQNMEWfDX5TOU3xs',
  },
  {
    url: 'okhash137.com',
    code: 'KQNMIGUCMwR3y25c',
  },
  {
    url: 'okhash138.com',
    code: 'KQNMNyFEysxABDgy',
  },
  {
    url: 'okhash139.com',
    code: 'KQNMSp2ecMJrDY5f',
  },
  {
    url: 'okhash140.com',
    code: 'KQNMXF2cxLjbzDZS',
  },
  {
    url: 'okhash141.com',
    code: 'KQNMbFxPYZrGNkYL',
  },
  {
    url: 'okhash142.com',
    code: 'KQNMf5UKwLXuNhzx',
  },
  {
    url: 'okhash143.com',
    code: 'KQNMjK4QBVXQK53U',
  },
  {
    url: 'okhash144.com',
    code: 'KQNMnrjTjBPfNx4E',
  },
  {
    url: 'okhash145.com',
    code: 'KQNMsEtUjOxwvgRL',
  },
  {
    url: 'okhash146.com',
    code: 'KQNMwXmtm0Nvxcqj',
  },
  {
    url: 'okhash147.com',
    code: 'KQNN152EsJzYXMFk',
  },
  {
    url: 'okhash148.com',
    code: 'KQNN5UZ6yfXXCr3x',
  },
  {
    url: 'okhash149.com',
    code: 'KQNN9i4sF3vLNo2f',
  },
  {
    url: 'okhash150.com',
    code: 'KQNNFDzdMMRGFGYt',
  },
  {
    url: 'okhash151.com',
    code: 'KRWhjZXAOGVsYoz0',
  },
  {
    url: 'okhash152.com',
    code: 'KRWhmPiiIjHfF2hS',
  },
  {
    url: 'okhash153.com',
    code: 'KRWhpWSE1j5EYtUq',
  },
  {
    url: 'okhash154.com',
    code: 'KRWhsNBbt1zAB467',
  },
  {
    url: 'okhash155.com',
    code: 'KRWhvLoy34RJ5abx',
  },
  {
    url: 'okhash156.com',
    code: 'KRWhyKjFBWx9MxHl',
  },
  {
    url: 'okhash157.com',
    code: 'KRWi1FzU1PjsTLg2',
  },
  {
    url: 'okhash158.com',
    code: 'KRWi4itURChZDipk',
  },
  {
    url: 'okhash159.com',
    code: 'KRWi7mLIVhPFSSbz',
  },
  {
    url: 'okhash160.com',
    code: 'KRWiAW4ChjD0VAfE',
  },
  {
    url: 'okhash161.com',
    code: 'KRWiD2yb3SJSuSdk',
  },
  {
    url: 'okhash162.com',
    code: 'KRWiFc1dMidzVqJ9',
  },
  {
    url: 'okhash163.com',
    code: 'KRWiIB9POBDRoIgy',
  },
  {
    url: 'okhash164.com',
    code: 'KRWiKjdFpglF3t45',
  },
  {
    url: 'okhash165.com',
    code: 'KRWiNUyJxKJOPt1T',
  },
  {
    url: 'okhash166.com',
    code: 'KRWiR6Tc35TLiMN9',
  },
  {
    url: 'okhash167.com',
    code: 'KRWiWeMrk4NXhYd7',
  },
  {
    url: 'okhash168.com',
    code: 'KRWiaoO48HXJFcmJ',
  },
  {
    url: 'okhash169.com',
    code: 'KRWidbiykW7SMkBb',
  },
  {
    url: 'okhash170.com',
    code: 'KRWii1ARBD5tU3RJ',
  },
  {
    url: 'okhash171.com',
    code: 'KRWilmZY4IpN863l',
  },
  {
    url: 'okhash172.com',
    code: 'KRWioLge8J56JuIB',
  },
  {
    url: 'okhash173.com',
    code: 'KRWis99fGjn0oOul',
  },
  {
    url: 'okhash174.com',
    code: 'KRWivCLEKGlH2dwy',
  },
  {
    url: 'okhash175.com',
    code: 'KRWiyCUemo34JQHK',
  },
  {
    url: 'okhash176.com',
    code: 'KRWj0mkoXmBQHl6v',
  },
  {
    url: 'okhash177.com',
    code: 'KRWj3AmKHehCe6zn',
  },
  {
    url: 'okhash178.com',
    code: 'KRWj7Kzhw5fiBXPl',
  },
  {
    url: 'okhash179.com',
    code: 'KRWjAR0YMwBAuSQn',
  },
  {
    url: 'okhash180.com',
    code: 'KRWjDWrFPvrgzKws',
  },
  {
    url: 'uthx21.com',
    code: 'KTF2MMbf44ltNwFF',
  },
  {
    url: 'uthx22.com',
    code: 'KTF2f35JcqtSTMMJ',
  },
  {
    url: 'uthx23.com',
    code: 'KTF2i6An3GxhdH1X',
  },
  {
    url: 'uthx24.com',
    code: 'KTF2lkERXvb3wjRj',
  },
  {
    url: 'uthx25.com',
    code: 'KTF2oi10uKto4Gpj',
  },
  {
    url: 'uthx26.com',
    code: 'KTF2rj2a5Pznde9R',
  },
  {
    url: 'uthx27.com',
    code: 'KTF2v46fxDLM9L4o',
  },
  {
    url: 'uthx28.com',
    code: 'KTF2xmOFYwZm58tq',
  },
  {
    url: 'uthx29.com',
    code: 'KTF310qEHuRkNGGH',
  },
  {
    url: 'uthx30.com',
    code: 'KTF34Wl1NmRMOu7c',
  },
  {
    url: '98sp.vip',
    code: 'KTOpq0DQSP1xRVrf',
  },
]

const googleDomainList = [
  {
    url: 'ok-01.store',
    code: 'GTM-P7WXXH4M',
  },
  {
    url: 'ok-02.online',
    code: 'GTM-MMCRK949',
  },
  {
    url: 'ok-07.online',
    code: 'GTM-MNWDZBF3',
  },
  {
    url: 'ok-07.store',
    code: 'GTM-TZWT2PTQ',
  },
  {
    url: 'ok-08.online',
    code: 'GTM-TGBPX55N',
  },
  {
    url: 'ok-02.store',
    code: 'GTM-MFGT6M55',
  },
  {
    url: 'ok-03.online',
    code: 'GTM-PCJPWMGH',
  },
  {
    url: 'ok-04.online',
    code: 'GTM-5X2KJPKF',
  },
  {
    url: 'ok-04.store',
    code: 'GTM-KL3VJSV5',
  },
  {
    url: 'ok-05.online',
    code: 'GTM-PLXGWN8S',
  },
  {
    url: 'ok-06.online',
    code: 'GTM-THST8RBJ',
  },
  {
    url: 'okhash.site',
    code: 'GTM-T8LMM94J',
  },
  {
    url: 'ok-08.store',
    code: 'GTM-NCXVB4Z4',
  },
  {
    url: 'ok-09.online',
    code: 'GTM-NLQ79WGS',
  },
  {
    url: 'ok-09.store',
    code: 'GTM-T6ZNF95S',
  },
  {
    url: 'ok-1.online',
    code: 'GTM-KGXPBSJJ',
  },
  {
    url: 'ok-2.online',
    code: 'GTM-WFW95QF6',
  },
  {
    url: 'ok-3.online',
    code: 'GTM-PS3B4DPW',
  },
  {
    url: 'ok-3.store',
    code: 'GTM-5RK6RLVR',
  },
  {
    url: 'ok-4.online',
    code: 'GTM-5STWCQHQ',
  },
  {
    url: 'ok-4.store',
    code: 'GTM-TFW3KK8X',
  },
  {
    url: 'k-5.online',
    code: 'GTM-TVNVKWKK',
  },
  {
    url: 'ok-5.store',
    code: 'GTM-WBTLSBXP',
  },
  {
    url: 'ok-6.online',
    code: 'GTM-T3KPNQ89',
  },
  {
    url: 'ok-6.store',
    code: 'GTM-TH5GJ97X',
  },
  {
    url: 'ok-7.store',
    code: 'GTM-MHHMJGLZ',
  },
  {
    url: 'ok-8.online',
    code: 'GTM-KVD7HR9S',
  },
  {
    url: 'ok-8.store',
    code: 'GTM-PBKVK8FM',
  },
  {
    url: 'ok-9.online',
    code: 'GTM-M49MLLV8',
  },
  {
    url: 'ok-9.store',
    code: 'GTM-5SR9M3J3',
  },
  {
    url: 'ok2.online',
    code: 'GTM-KD7WXLMQ',
  },
  {
    url: 'ok3.store',
    code: 'GTM-5PTHZHWC',
  },
  {
    url: 'ok6.store',
    code: 'GTM-PFB45LZ2',
  },
  {
    url: 'ok7.online',
    code: 'GTM-WXGM9CCH',
  },
  {
    url: 'ok7.store',
    code: 'GTM-TBSJVRCX',
  },
  {
    url: 'ok8.store',
    code: 'GTM-W6JPQ8DS',
  },
  {
    url: 'ok9.online',
    code: 'GTM-WL3CWP7L',
  },
  {
    url: 'ok9.store',
    code: 'GTM-MHB97R7R',
  },
  {
    url: 'ok01.online',
    code: 'GTM-5T99L4KQ',
  },
  {
    url: 'ok01.store',
    code: 'GTM-KX7TQW7P',
  },
  {
    url: 'ok02.online',
    code: 'GTM-NC5SRNPV',
  },
  {
    url: 'ok03.online',
    code: 'GTM-NTXWQRS7',
  },
  {
    url: 'ok03.store',
    code: 'GTM-WPRRVFNB',
  },
  {
    url: 'ok04.online',
    code: 'GTM-PW3FZ5P5',
  },
  {
    url: 'ok04.store',
    code: 'GTM-PFC9QPCP',
  },
  {
    url: 'ok05.online',
    code: 'GTM-MRN3X3QC',
  },
  {
    url: 'ok11.online',
    code: 'GTM-M2KQKSRB',
  },
  {
    url: 'ok13.store',
    code: 'GTM-M9ZM6FXP',
  },
  {
    url: 'ok14.online',
    code: 'GTM-KMVV2WMH',
  },
  {
    url: 'ok14.store',
    code: 'GTM-W9MFC3P8',
  },
  {
    url: 'ok15.store',
    code: 'GTM-NGNGC3CL',
  },
  {
    url: 'ok18.online',
    code: 'GTM-MCHNKCVX',
  },
  {
    url: 'ok18.store',
    code: 'GTM-52MZVNKS',
  },
  {
    url: 'ok-02.store',
    code: 'GTM-M3WQH8RT',
  },
  {
    url: 'ok-03.store',
    code: 'GTM-N733P4ZH',
  },
  {
    url: 'ok-33.online',
    code: 'GTM-MS8X7R8V',
  },
  {
    url: 'okhash.one',
    code: 'GTM-NS7FPJ2W',
  },
]

export const trafficTrack = function () {
  let currentDomain = top.location.href
  let matchDomain = domainNameList.find((item) => currentDomain.indexOf(item.url) != -1)
  if (matchDomain?.code) {
    var _hmt = _hmt || []
    ;(function () {
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?' + matchDomain.code
      var s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    })()
  }
}

export const trafficTrack2 = function () {
  let currentDomain = top.location.href
  let matchDomain = domainList.find((item) => currentDomain.indexOf(item.url) != -1)
  if (matchDomain?.code) {
    !(function (p) {
      'use strict'
      !(function (t) {
        var s = window,
          e = document,
          i = p,
          c = ''.concat('https:' === e.location.protocol ? 'https://' : 'http://', 'sdk.51.la/js-sdk-pro.min.js'),
          n = e.createElement('script'),
          r = e.getElementsByTagName('script')[0]
        ;(n.type = 'text/javascript'),
          n.setAttribute('charset', 'UTF-8'),
          (n.async = !0),
          (n.src = c),
          (n.id = 'LA_COLLECT'),
          (i.d = n)
        var o = function () {
          s.LA.ids.push(i)
        }
        s.LA ? s.LA.ids && o() : ((s.LA = p), (s.LA.ids = []), o()), r.parentNode.insertBefore(n, r)
      })()
    })({ id: matchDomain.code, ck: matchDomain.code })
  }
}

export const addGoogleScript = function () {
  let currentDomain = top.location.href
  let matchDomain = googleDomainList.find((item) => currentDomain.indexOf(item.url) != -1)
  if (matchDomain?.code) {
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
      var ifr = document.createElement('iframe')
      var noc = document.querySelector('noscript')
      ifr.src = `https: //www.googletagmanager.com/ns.html?id=${matchDomain.code}`
      ifr.height = '0'
      ifr.width = '0'
      ifr.style = 'display:none;visibility:hidden'
      noc.appendChild(ifr)
    })(window, document, 'script', 'dataLayer', matchDomain.code)
  }
}
