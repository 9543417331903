/*
 * @Description: 字符串扩展
 * @Author: anji
 * @Date: 2021-11-18 18:11:39
 * @LastEditTime: 2021-11-18 18:11:39
 * @LastEditors: anji
 */
import i18n from '@/lang'

const statusName = {
  3: '低于下注限额',
  4: '高于下注限额',
  5: '无效下注',
  301: '特殊区块退回',
  500: '待开奖',
}

export const getStatusName = function (state) {
  if (statusName[state]) {
    return i18n.t('game.statusName_' + state)
  } else {
    return null
  }
}

export const hideMiddle = function (num, start, end) {
  let str = num + ''
  if (str) {
    return `${str.substring(0, start)}****${str.substring(str.length - end)}`
  }
}

export const hideEnd = function (str, maxLenth) {
  if (str && str.length > maxLenth) {
    return `${str.substring(0, maxLenth)}...`
  }
  return str
}

export const getResultName = function (arr, GameId) {
  if (!arr || arr.length <= 0) return '--'
  if (GameId % 10 == 1) {
    return arr == '大' ? i18n.t('static.betAreaList1') : i18n.t('static.betAreaList2')
  } else if (GameId % 10 == 2) {
    return arr == '双' ? i18n.t('static.betAreaList4') : i18n.t('static.betAreaList3')
  } else if (GameId % 10 == 3) {
    let zg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{2}$/
    return zg.test(arr) ? i18n.t('static.betAreaList9') : i18n.t('static.betAreaList10')
  } else if (GameId % 10 == 4 || GameId % 10 == 5) {
    let nums = arr.split('-')
    nums[0] = Number(nums[0])
    nums[1] = Number(nums[1])
    return nums[0] > nums[1]
      ? i18n.t('static.betAreaList5')
      : nums[0] < nums[1]
      ? i18n.t('static.betAreaList7')
      : i18n.t('static.betAreaList6')
  }
}

export const getOpenResult = function (Period, GameId) {
  let blockArr = String(Period).slice(-5)
  if (GameId % 10 == 1 || GameId % 10 == 2) {
    const regex = /[0-9]/
    let index = -1
    for (let i = blockArr.length - 1; i >= 0; i--) {
      if (regex.test(blockArr[i])) {
        index = i
        break
      }
    }
    if (index !== -1) {
      const firstPart = blockArr.slice(0, index)
      const secondPart = blockArr.slice(index)
      const firstNum = secondPart.slice(0, 1)
      const letters = secondPart.slice(1, secondPart.length)
      if (secondPart.length > 1) {
        return `${firstPart}<span style="color: #2B8CFF">${firstNum}</span>${letters}`
      } else {
        return `${firstPart}<span style="color: #2B8CFF">${firstNum}</span>`
      }
    } else {
      return null
    }
  } else if (GameId % 10 == 3) {
    const startArr = blockArr.substr(0, 3)
    const endArr = blockArr.substr(blockArr.length - 2)
    return `${startArr}<span style="color: #2B8CFF">${endArr}</span>`
  } else {
    const startArr = blockArr.substr(0, 5)
    return `<span style="color: #2B8CFF">${startArr}</span>`
  }
}

export const transBlockHash = function (Period, GameId) {
  if (!Period) return
  let startArr = Period.substring(0, 2)
  let endArr = Period.substring(Period.length - 9)
  if (GameId % 10 == 1 || GameId % 10 == 2) {
    const regex = /[0-9]/
    let index = -1
    for (let i = endArr.length - 1; i >= 0; i--) {
      if (regex.test(endArr[i])) {
        index = i
        break
      }
    }
    if (index !== -1) {
      const firstPart = endArr.slice(0, index)
      const secondPart = endArr.slice(index)
      const firstNum = secondPart.slice(0, 1)
      const letters = secondPart.slice(1, secondPart.length)
      if (secondPart.length > 1) {
        return `${startArr}**${firstPart}<span style="color: #61C597">${firstNum}</span>${letters}`
      } else {
        return `${startArr}**${firstPart}<span style="color: #61C597">${firstNum}</span>`
      }
    } else {
      return null
    }
  } else if (GameId % 10 == 3) {
    const arr1 = endArr.substr(0, 7)
    const arr2 = endArr.substr(endArr.length - 2)
    return `${startArr}**${arr1}<span style="color: #61C597">${arr2}</span>`
  } else {
    const arr1 = endArr.substr(0, 4)
    const arr2 = endArr.substr(endArr.length - 5)
    return `${startArr}**${arr1}<span style="color: #61C597">${arr2}</span>`
  }
}

export const handleOrderResult = function (value) {
  if (value.GameId == 201) {
    if (value.OpenArea == '大') {
      value.OpenArea = i18n.t('static.betAreaList1')
    } else {
      value.OpenArea = i18n.t('static.betAreaList2')
    }
    if (value.IsWin == 1) {
      value.rewardArea = '+' + value.RewardAmount
    } else if (value.IsWin == 2) {
      value.rewardArea = '-' + value.Amount
    }
  } else if (value.GameId == 202) {
    if (value.OpenArea == '单') {
      value.OpenArea = i18n.t('static.betAreaList3')
    } else {
      value.OpenArea = i18n.t('static.betAreaList4')
    }
    if (value.IsWin == 1) {
      value.rewardArea = '+' + value.RewardAmount
    } else if (value.IsWin == 2) {
      value.rewardArea = '-' + value.Amount
    }
  } else if (value.GameId == 203) {
    if (value.IsWin == 1) {
      value.OpenArea = i18n.t('static.betAreaList9')
      value.rewardArea = '+' + value.RewardAmount
    } else if (value.IsWin == 2) {
      value.OpenArea = i18n.t('static.betAreaList10')
      value.rewardArea = '-' + value.Amount
    }
  } else if (value.GameId == 204 && value.OpenArea) {
    let nums = value.OpenArea.split('-')
    if (nums[0] > nums[1]) {
      value.OpenArea = i18n.t('static.betAreaList5')
    } else if (nums[0] < nums[1]) {
      value.OpenArea = i18n.t('static.betAreaList7')
    } else {
      value.OpenArea = i18n.t('static.betAreaList6')
    }
    if (value.IsWin == 1) {
      value.rewardArea = '+' + value.RewardAmount
    } else {
      if (nums[0] == nums[1]) {
        value.rewardArea = '+' + value.RewardAmount
        value.isLoseHe = true
      } else {
        value.rewardArea = '-' + value.Amount
      }
    }
  } else if (value.GameId == 205 && value.OpenArea) {
    let nums = value.OpenArea.split('-')
    if (nums[0] > nums[1]) {
      var liu = nums[0].split('')[1]
      if (liu == '牛') liu = i18n.t('game.pageBet.ox')
      value.OpenArea = i18n.t('static.betAreaList5') + i18n.t('static.betAreaList11') + i18n.t('game.pageBet.ox') + liu
    } else if (nums[0] < nums[1]) {
      var liu = nums[1].split('')[1]
      if (liu == '牛') liu = i18n.t('game.pageBet.ox')
      value.OpenArea = i18n.t('static.betAreaList7') + i18n.t('static.betAreaList11') + i18n.t('game.pageBet.ox') + liu
    } else {
      var liu = nums[0].split('')[1]
      if (liu == '牛') liu = i18n.t('game.pageBet.ox')
      value.IsWin = 3
      value.OpenArea = i18n.t('game.pageBet.ox') + liu + i18n.t('static.betAreaList6')
    }
    if (value.IsWin == 1) {
      value.rewardArea = '+' + value.RewardAmount
    } else if (value.IsWin == 3) {
      value.rewardArea = value.RewardAmount
    } else if (value.IsWin == 2) {
      value.rewardArea = '-' + (value.Amount - value.RewardAmount)
    }
  }
  return value
}

export const getWinnigText = function (item) {
  if (item.GameId % 10 == 5) {
    let nums = item.OpenArea.split('-')
    let liu
    if (nums[0].length == 2 && nums[1].length == 2) {
      if (item.IsWin == 1) {
        liu = nums[1].split('')[1]
      } else {
        liu = nums[0].split('')[1]
      }
      if (liu == '牛') liu = i18n.t('game.pageBet.ox')
    } else {
      if (item.IsWin == 1) {
        liu = nums[1]
      } else {
        liu = nums[0]
      }
      if (liu == 10) liu = i18n.t('game.pageBet.ox')
    }
    if (item.IsWin == 1) {
      return i18n.t('static.betAreaList7') + i18n.t('static.betAreaList11') + i18n.t('game.pageBet.ox') + liu
    } else if (statusName[item.State]) {
      return getStatusName(item.State)
    } else if (nums[1] == nums[0]) {
      return i18n.t('game.pageBet.ox') + liu + i18n.t('static.betAreaList6')
    } else {
      return i18n.t('static.betAreaList5') + i18n.t('static.betAreaList11') + i18n.t('game.pageBet.ox') + liu
    }
  } else {
    if (item.IsWin == 1) {
      return i18n.t('game.transferBet_bigexample1_6')
    } else if (statusName[item.State]) {
      return getStatusName(item.State)
    } else {
      return i18n.t('game.transferBet_bigexample2_1')
    }
  }
}

export const getGameFee = function (Data, GameId, RoomLevel) {
  let feeInfo = {
    gameFee: null,
    heGameFee: null,
  }
  let feeData = Data[GameId]
  if (feeData && feeData[RoomLevel]) {
    feeData = feeData[RoomLevel]
    if (GameId % 10 == 4 || GameId % 10 == 5) {
      if (feeData['1'] && feeData['1'].v && feeData['1'].s == 1) {
        feeInfo.gameFee = this.calculateFee(feeData['1'].v)
      }
      if (GameId % 10 == 4 && feeData['2'] && feeData['2'].v && feeData['2'].s == 1) {
        feeInfo.heGameFee = feeData['2'].v
      }
    } else if (feeData.v && feeData.s == 1) {
      feeInfo.gameFee = this.calculateFee(feeData.v)
    }
  }
  return feeInfo
}

export const calculateFee = (value) => {
  var d = String(value).split('.')
  if (d.length > 1) {
    var sum = Math.pow(10, d[1].length)
    return (2 * sum - value * sum) / sum
  } else {
    return 2 - value
  }
}

export const abbreviateNumber = (number) => {
  if (number >= 1000) {
    var suffixes = ['', 'K', 'M', 'B', 'T']
    var suffixNum = Math.floor(('' + number).length / 3)
    var shortValue = ''
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum != 0 ? number / Math.pow(1000, suffixNum) : number).toPrecision(precision))
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1)
    number = shortValue + suffixes[suffixNum]
  }
  return number
}

export const abbreviateNumber2 = (number) => {
  if (number >= 1000) {
    var suffixe = 'K'
    return number / 1000 + suffixe
  } else {
    return number
  }
}

export const sliceBlockHash = (hash, start, end) => {
  if (hash.length > start) {
    end = hash.length < end || !end ? hash.length : end

    return hash.slice(start, end)
  } else {
    return hash
  }
}

export const handleTransferRecord = (item) => {
  if (item.GameId == 1 || item.GameId == 11) {
    if (item.OpenArea == '大') {
      item.OpenArea = i18n.t('static.betAreaList1')
      item.iconStart = 1
    } else {
      item.OpenArea = i18n.t('static.betAreaList2')
      item.iconStart = 2
    }
    if (item.BetArea == '大') {
      item.BetArea = i18n.t('static.betAreaList1')
      item.betStart = 1
    } else {
      item.BetArea = i18n.t('static.betAreaList2')
      item.betStart = 2
    }
    if (item.IsWin == 1) {
      item.rewardArea = '+' + item.RewardAmount
    } else if (item.IsWin == 2) {
      item.rewardArea = '-' + item.Amount
    }
  } else if (item.GameId == 2 || item.GameId == 12) {
    if (item.OpenArea == '单') {
      item.OpenArea = i18n.t('static.betAreaList3')
      item.iconStart = 1
    } else {
      item.OpenArea = i18n.t('static.betAreaList4')
      item.iconStart = 2
    }
    if (item.BetArea == '单') {
      item.BetArea = i18n.t('static.betAreaList3')
      item.betStart = 1
    } else {
      item.BetArea = i18n.t('static.betAreaList4')
      item.betStart = 2
    }
    if (item.IsWin == 1) {
      item.rewardArea = '+' + item.RewardAmount
    } else if (item.IsWin == 2) {
      item.rewardArea = '-' + item.Amount
    }
  } else if (item.GameId == 3) {
    if (item.IsWin == 1) {
      item.OpenArea = i18n.t('static.betAreaList9')
      item.rewardArea = '+' + item.RewardAmount
      item.iconStart = 1
    } else if (item.IsWin == 2) {
      item.OpenArea = i18n.t('static.betAreaList10')
      item.rewardArea = '-' + item.Amount
      item.iconStart = 2
    }
  } else if (item.GameId == 4 && item.OpenArea) {
    let nums = item.OpenArea.split('-')
    if (nums[0] > nums[1]) {
      item.OpenArea = i18n.t('static.betAreaList5')
      item.iconStart = 1
    } else if (nums[0] < nums[1]) {
      item.OpenArea = i18n.t('static.betAreaList7')
      item.iconStart = 2
    } else {
      item.OpenArea = i18n.t('static.betAreaList6')
      item.iconStart = 3
    }
    if (item.BetArea == '庄') {
      item.betStart = 1
      item.BetArea = i18n.t('static.betAreaList5')
    } else if (item.BetArea == '闲') {
      item.BetArea = i18n.t('static.betAreaList7')
      item.betStart = 2
    } else if (item.BetArea == '和') {
      item.BetArea = i18n.t('static.betAreaList6')
      item.betStart = 3
    }
    if (item.IsWin == 1) {
      item.rewardArea = '+' + item.RewardAmount
    } else {
      if (nums[0] == nums[1]) {
        item.rewardArea = '+' + item.RewardAmount
        item.isLoseHe = true
      } else {
        item.rewardArea = '-' + item.Amount
      }
    }
  } else if (item.GameId == 5 && item.OpenArea) {
    let nums = item.OpenArea.split('-')
    if (nums[0] > nums[1]) {
      var liu = nums[0].split('')[1]
      if (liu == '牛') liu = i18n.t('game.pageBet.ox')
      item.iconStart = 1
      item.OpenArea = i18n.t('static.betAreaList5') + i18n.t('static.betAreaList11') + i18n.t('game.pageBet.ox') + liu
    } else if (nums[0] < nums[1]) {
      var liu = nums[1].split('')[1]
      item.iconStart = 2
      if (liu == '牛') liu = i18n.t('game.pageBet.ox')
      item.OpenArea = i18n.t('static.betAreaList7') + i18n.t('static.betAreaList11') + i18n.t('game.pageBet.ox') + liu
    } else {
      var liu = nums[0].split('')[1]
      item.iconStart = 3
      if (liu == '牛') liu = i18n.t('game.pageBet.ox')
      item.IsWin = 3
      item.OpenArea = i18n.t('game.pageBet.ox') + liu + i18n.t('static.betAreaList6')
    }
    if (item.IsWin == 1) {
      item.rewardArea = '+' + item.RewardAmount
    } else if (item.IsWin == 3) {
      item.rewardArea = item.RewardAmount
    } else if (item.IsWin == 2) {
      item.rewardArea = '-' + Math.round((item.Amount - item.RewardAmount) * 100000) / 100000
    }
  }
  if (statusName[item.State]) {
    item.IsWin = 0
    item.OpenArea = getStatusName(item.State)
    item.iconStart = 0
    return item
  }
  return item
}

export const getStringArr = (value, startLen, endLen) => {
  var result = []
  if (!value) {
    result = ['', '', '']
  } else if (value.length < startLen + endLen) {
    result = ['', value, '']
  } else {
    result = [
      value.substring(0, startLen),
      value.substring(startLen, value.length - endLen),
      value.substring(value.length - endLen),
    ]
  }
  return result
}

/**
 * 获得活动场馆
 * @param {String} pStrGameType
 * @return {String}
 */
export const getEventVenue = function (pStrGameType) {
  // 活动场馆
  let eventVenueNames = {
    0: i18n.t('active.words12_no'), // 不限场馆
    1: i18n.t('common.comFooter.game1'), // 哈希竞猜
    3: i18n.t('common.comFooter.game1'), // 哈希竞猜
    101: i18n.t('home.hashNav3'), // 哈希彩票
    102: i18n.t('common.comFooter.game2'), // 电子游戏
    103: i18n.t('common.comFooter.game4'), // 棋牌游戏
    104: i18n.t('home.topNav4'), // 加密游戏
    105: i18n.t('common.comFooter.game5'), // 真人视讯
    106: i18n.t('common.comFooter.game6'), // 体育竞技
  }

  if (!pStrGameType || pStrGameType.length < 1) {
    return eventVenueNames[0]
  }
  let arr = pStrGameType.split(',')
  let strArr = []
  for (let i = 0; i < arr.length; ++i) {
    let tmpStr = eventVenueNames[parseInt(arr[i])]
    if (tmpStr && strArr.indexOf(tmpStr) < 0) strArr.push(tmpStr)
  }
  return strArr.join(',')
}
