/*
 * @Description: websocket
 * @Author: anji
 * @Date: 2021-11-04 20:46:52
 * @LastEditTime: 2021-11-18 18:11:08
 * @LastEditors: anji
 */
export class ws {}
let conn = null;
let walletAddress;
let orderListener;
let interval;
function connect() {
  if (conn) return;
  let url;
  if (location.protocol == "https:") {
    url = "wss://" + window.location.host + "/api/ws";
  } else {
    url = "ws://" + window.location.host + "/api/ws";
  }
  conn = new WebSocket(url);
  conn.onopen = () => {
    interval = setInterval(() => {
      if (conn) {
        conn.send("ping");
      }
    }, 5000);
    if (walletAddress) {
      conn.send(walletAddress);
    }
  };
  conn.onclose = () => {
    if (conn) {
      conn.onopen = () => {};
      conn.onmessage = () => {};
      conn = null;
      clearInterval(interval);
      setTimeout(connect, 1000);
    }
  };
  conn.onerror = () => {
    if (conn) {
      conn.onopen = () => {};
      conn = null;
      clearInterval(interval);
      setTimeout(connect, 1000);
    }
  };
  conn.onmessage = (message) => {
    try {
      if (message.data == "ping") return;
      let jdata = JSON.parse(message.data);
      if (orderListener) orderListener(jdata);
    } catch (e) {}
  };
}

ws.close = function () {
  clearInterval(interval);
  if (conn) {
    conn.onclose();
    conn = null;
  }
};
ws.reconnect = function () {
  clearInterval(interval);
  if (conn) {
    conn.onclose = () => {};
    conn.onerror = () => {};
    conn = null;
  }
  walletAddress = null;
  connect();
};
ws.init = function (UserId) {
  if (UserId) {
    walletAddress = JSON.stringify({
      MsgType: "attach",
      Data: {
        UserId: Number(UserId),
      },
    });
  }
  connect();
};

ws.setOrderListener = (listener) => {
  orderListener = listener;
};

ws.attach = (UserId) => {
  if (!UserId) return;
  walletAddress = JSON.stringify({
    MsgType: "attach",
    Data: {
      UserId: Number(UserId),
    },
  });
  if (!conn) return;
  conn.send(walletAddress);
};
