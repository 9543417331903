<template>
  <div id="app">
    <keep-alive v-if="isLogin">
      <!-- 需要缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive>

    <!-- 不需要缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive"> </router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLogin:false
    };
  },
  created() {
    this.openWs();
    this.$store.dispatch("app/getAppInfo").then((data) => {
      this.$bus.$emit("initGAuth", data.SellerId);
    });
    // this.$store.dispatch("user/getCustomService");
    this.$store.dispatch("userStore/getService");

    if (localStorage.getItem("token")) {
      this.$store.commit("updateIsLogin", true);
      this.$store.dispatch("user/getGamePeilv");
    }
  },
  watch:{
    $route(to,from){
      let token = localStorage.getItem("token") || ''
      if(token){
        this.isLogin = true
      }else {
        this.isLogin = false
      }
    }
  },
  methods: {
    openWs() {
      this.$utils.ws.setOrderListener((res) => {
        if (res && res.Data) {
          if (res.MsgType == "order_update") {
            this.$bus.$emit("orderUpdate", res.Data);
          } else if (res.MsgType == "block_update") {
            this.$bus.$emit("blockUpdate", res.Data);
          } else if (res.MsgType == "order_win") {
            this.$bus.$emit("orderWin", res.Data);
          } else if (res.MsgType == "luzi") {
            this.$bus.$emit("luzi", res.Data);
          } else if (res.MsgType == "hash_bet_info") {
            this.$bus.$emit("updateHashBetInfo", res.Data);
            // 正式环境
          } else if (res.MsgType == "attach") {
            this.$store.commit("user/setBalance", res.Data.Balance);
            this.$bus.$emit("attach", res.Data);
            localStorage.setItem("attach", JSON.stringify(res.Data));
          } else if (res.MsgType == "UtLogList") {
            this.$bus.$emit("UtLogList", res.Data);
            let arr = res.Data.map((item) => JSON.parse(item));
            localStorage.setItem("UtLogList", JSON.stringify(arr));
          } else if (res.MsgType == "UtOrderList") {
            this.$bus.$emit("UtOrderList", res.Data);
            let arr = res.Data.map((item) => JSON.parse(item));
            localStorage.setItem("UtOrderList", JSON.stringify(arr));
          } else if (res.MsgType == "UtOrderDataInfo") {
            this.$bus.$emit("UtOrderDataInfo", res.Data);
            localStorage.setItem("UtOrderDataInfo", JSON.stringify(res.Data));
          } else if (res.MsgType == "UtBalance") {
            this.$bus.$emit("UtBalance", res.Data);
          } else if (
            res.MsgType == "UtLastBlock" ||
            res.MsgType == "UtStopTask" ||
            res.MsgType == "UtTxId" ||
            res.MsgType == "UtBuyBlock"
          ) {
            this.$bus.$emit("UtLastBlock", res);
          } else if (res.MsgType == "UtOrder") {
            this.$bus.$emit("UtOrder", res);
          } else if (res.MsgType == "UtAddressBalance") {
            this.$bus.$emit("UtAddressBalance", res);
          }
          // 测试环境
          else if (res.MsgType == "UtTestLogList") {
            this.$bus.$emit("UtTestLogList", res.Data);
            let arr = res.Data.map((item) => JSON.parse(item));
            localStorage.setItem("UtTestLogList", JSON.stringify(arr));
          } else if (res.MsgType == "UtTestOrderList") {
            this.$bus.$emit("UtTestOrderList", res.Data);
            let arr = res.Data.map((item) => JSON.parse(item));
            localStorage.setItem("UtTestOrderList", JSON.stringify(arr));
          } else if (res.MsgType == "UtTestOrderDataInfo") {
            this.$bus.$emit("UtTestOrderDataInfo", res.Data);
            localStorage.setItem(
              "UtTestOrderDataInfo",
              JSON.stringify(res.Data)
            );
          } else if (res.MsgType == "UtTestBalance") {
            this.$bus.$emit("UtTestBalance", res.Data);
          } else if (
            res.MsgType == "UtTestLastBlock" ||
            res.MsgType == "UtTestStopTask" ||
            res.MsgType == "UtTestTxId" ||
            res.MsgType == "UtTestBuyBlock"
          ) {
            this.$bus.$emit("UtTestLastBlock", res);
          } else if (res.MsgType == "UtTestOrder") {
            this.$bus.$emit("UtTestOrder", res);
          } else if (res.MsgType == "UtTestAddressBalance") {
            this.$bus.$emit("UtTestAddressBalance", res);
          }
        }
      });
      this.$utils.ws.init(JSON.parse(localStorage.getItem("UserId")));
    },
    mounted() {
      // 提取查询字符串
      const queryString = location.href.split("?")[1];

      if (!queryString) return;

      // 解析查询参数
      const queryParams = {};
      try {
        if (queryString) {
          const pairs = queryString.split("&");
          for (const pair of pairs) {
            const [key, value] = pair.split("=");
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
          }
        }
      } catch (error) {}

      if (!queryParams.AgentCode) return;
      localStorage.setItem("AgentCode", queryParams.AgentCode);
    },
  },
};
</script>
<style lang="scss">
#app {
  height: 100%;
  width: 100%;
}
</style>
