import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Toast } from "vant";
import i18n from "@/lang";
import "./api/old_client_index";
import "./core/vant";
import "./assets/css/common.css";
import "vant/lib/index.css";
import "./style/vant.scss";
import "@/assets/css/index.scss";
import NavPage from "./components/NavPage.vue";
import "@/assets/icons";
import "./utils";
import VueCookies from "vue-cookies";
import filters from './filters';
import 'lib-flexible'
import VueClipboard from 'vue-clipboard2'
// import Vconsole from 'vconsole'
// new Vconsole()
const bus = new Vue();
Vue.prototype.$bus = bus;
Vue.prototype.$cookies = VueCookies;
Vue.use(filters)
Vue.use(VueClipboard)
Toast.setDefaultOptions("loading", { forbidClick: true, duration: 0 });

Vue.component("NavPage", NavPage);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
