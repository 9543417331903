import axios from 'axios'
import store from '@/store'
import { addPendingRequest, removePendingRequest, errorHandle } from './utils'
// 创建axios实例
const instance = axios.create({
  baseURL: '/api',
  timeout: 1000 * 30,
  // cancelToken: source.token // 取消请求（比如你进入某个路由后，可能就停在了这个页面就1s时间， 就立马进入了下个路由，这时，前边这个路由的请求就可以取消掉了）
})

let reqNum = 0;
const startLoading = () => {
  if (reqNum === 0) {
    //loading 开始
    store.commit("setLoading", true);
  }
  reqNum++;
};
const endLoading = () => {
  if (reqNum <= 0) return;
  reqNum--;
  if (reqNum === 0) {
    //loading 结束
    store.commit("setLoading", false);
  }
};

instance.interceptors.request.use(
  function (config) {
    // 重复请求校验
    // removePendingRequest   (config) // 检查是否存在重复请求，若存在则取消已发的请求
    // addPendingRequest(config) // 把当前请求添加到pendingRequest对象中
    const token = localStorage.getItem('token')
    config.headers = {
      ...config.headers,
      'X-Token': token // 添加token
    }
    config.loading && startLoading()
    return config
  },
  (error) => {
    endLoading();
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    // removePendingRequest(response.config) // 从pendingRequest对象中移除请求
    endLoading();
    if (response.data.code == 200) {
      return response
    } else {
      endLoading();
      errorHandle(response.data.code, response.data.data)
      return Promise.reject(response)
    }
  },
)

export default instance
