import api from '@/api/old_client_index'

const state = {
  appInfo: {},
  withdrawConfig: {},
  ExchangeInfo: {},
  AnnmtList: [],
}
const mutations = {
  setAppInfo(state, data) {
    state.appInfo = data;
  },
  setWithdrawConfig(state, data) {
    state.withdrawConfig = data;
  },
  setExchangeInfo(state, data) {
    state.ExchangeInfo = data;
  },
  setAnnmtList(state, data) {
    state.AnnmtList = data;
  },
}
const actions = {
  async getAppInfo({ commit }) {
    const res = await api.app.appInfo()
    if (res.code === 200) {
      commit('setAppInfo', res.data)
      localStorage.setItem('appInfo', JSON.stringify(res.data))
      document.title = res.data.Name
      return Promise.resolve(res.data)
    }
  },
  async getWithdrawConfig({ commit }) {
    const res = await api.app.withdrawConfig()
    if (res.code === 200) {
      commit('setWithdrawConfig', res.data)
    }
  },
  async getSendSms({ }, data) {
    const res = await api.app.sendSms(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getAnnmt({ commit }, data) {
    const res = await api.app.Annmt(data)
    if (res.code === 200) {
      commit('setAnnmtList', res.data.notice)
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getChatLogin({ }, data) {
    const res = await api.app.chatLogin(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async geteExchangeInfo({ commit }) { // 兑换信息
    const res = await api.app.exchangeInfo()
    if (res.code === 200) {
      commit('setExchangeInfo', res.data)
    }
  },
  async getExchangeCalc({ }, data) {
    const res = await api.app.exchangeCalc(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
