import api from '@/api/old_client_index'
import utils from '@/utils'
const state = {
  ActiveList: []
}
const mutations = {
  setActiveList (state, data) {
    data.forEach(item => {
      const nowTime = new Date().getTime()
      if(item.EffectStartTime && item.EffectEndTime  && (item.EffectStartTime > nowTime || item.EffectEndTime < nowTime)) {
        item.State = 2
      }
      item.EffectEndTime = utils.date.format(item.EffectEndTime, 'YYYY-MM-DD')
      item.EffectStartTime = utils.date.format(item.EffectStartTime, 'YYYY-MM-DD')
      
    })
    data = data.filter(item => item.State === 1)
    state.ActiveList = data
  }
}
const actions = {
  async getActiveList ({ commit }) {
    const res = await api.active.activeDefine()
    commit('setActiveList', res.data)
  },
  async getactiveRecord ({ }, data) {
    const res = await api.active.activeRecord(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getActiveInfo ({ }, data) {
    const res = await api.active.activeInfo(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
