/*
 * @Description: 时间处理
 * @Author: anji
 * @Date: 2021-11-04 20:46:52
 * @LastEditTime: 2021-11-18 18:11:08
 * @LastEditors: anji
 */
import rdate from 'rdatejs'

//月份英文配置
const dateMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const addDate = function (dates, days) {
  if (dates == null || dates == '') {
    return ''
  } else {
    var date = new Date(dates)
    date.setDate(date.getDate() + days)
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var mm = "'" + month + "'"
    var dd = "'" + day + "'"
    if (mm.length == 3) {
      month = '0' + month
    }
    if (dd.length == 3) {
      day = '0' + day
    }
    var time = year + '.' + month + '.' + day
    return time
  }
}

export const getDiffDate = function (data) {
  // 传入时间与当前时间相差天数
  let date1 = new Date(data)
  let date2 = new Date()
  date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate())
  date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
  const diff = date2.getTime() - date1.getTime()
  const diffDate = diff / (24 * 60 * 60 * 1000)
  if (diffDate < 0) return 0
  return diffDate
}

export const handleDateType = {
  //今日
  today: (() => {
    let start = rdate.startOf('day')
    let end = rdate.endOf('day')
    return { start, end }
  })(),
  //昨日
  yesterday: (() => {
    let start = rdate.startOf('day', rdate.subtract(1, 'day'))
    let end = rdate.endOf('day', rdate.subtract(1, 'day'))
    return { start, end }
  })(),
  //近7天
  week: (() => {
    let start = rdate.startOf('day', rdate.subtract(7, 'day'))
    let end = rdate.endOf('day')
    return { start, end }
  })(),
  //近30天
  month: (() => {
    let start = rdate.startOf('day', rdate.subtract(30, 'day'))
    let end = rdate.endOf('day')
    return { start, end }
  })(),
}

/**
 * 判断活动时间是否是不是长期活动
 * @param {Number|String} pStartTime
 * @param {Number|String} pEndTime
 * @return {Boolean} true: 不是长期活动
 */
export const isNotLongActive = function (pStartTime, pEndTime) {
  if ((pEndTime == '1970-01-01' && pStartTime == '1970-01-01') || pEndTime === 0 || pStartTime === 0) {
    return false
  }
  return true
}

/**
 * 日期转换英文写法
 * @param {Number|String} time
 * @param {Number} type 1 年月日;2月日;3日
 */
export const dateFormatToEn = function (time, type) {
  var date = rdate.toObject(time)
  var y = date.year
  var m = date.month
  var d = date.date
  if (type == 1) {
    return `${dateMonths[Number(m) - 1]} ${d}th,${y}`
  } else if (type == 2) {
    return `${dateMonths[Number(m) - 1]} ${d}th`
  } else {
    return `${d}th`
  }
}

/**
 * 活动日期转换英文写法
 * @param {String} pActiveTime 2024-06-01
 * @return {String}
 */
export const doActiveTimeToEn = function (pActiveTime) {
  let strLang = localStorage.getItem('lang') || 'zh'
  if (strLang != 'en') return pActiveTime
  let tList = pActiveTime.split('-')
  if (tList.length < 3) return pActiveTime

  let strYear = tList[0]
  let nMonth = parseInt(tList[1]) - 1
  let nDay = parseInt(tList[2])
  let strMonth = dateMonths[nMonth]
  return strMonth + ' ' + nDay + ', ' + strYear
}
