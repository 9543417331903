import store from "@/store";

/**
 * @description 合并请求体（默认会合并用户的SellerId---不存在则为undefined）
 * @param {object} payload 被合并的参数，该参数请以对象类型为入参
 * @param {object} extraParam 额外需要合并的参数
 */
export function mergeBody(payload = {}, extraParam = {}) {
  return Object.assign(
    {},
    {
      SellerId: store.state.userStore.userInfo.SellerId || 1,
      ...extraParam,
    },
    payload
  );
}
