

const ColorList = [
  '#3F80FF',
  '#FC73FF',
  '#137635',
  '#103164',
  '#CB3717',
  '#B18857',
  '#B65C57',
  '#69A9A9',
  '#6E9CAB',
  '#B77270',
  '#DD9D1F',
  '#DD9D1F',
  '#DD9D1F',
  '#DD9D1F',
  '#DD9D1F',
]

export const getChipColor = function (index) {
  const color = ColorList[index]
  if(color) {
    return color
  }
}
