<template>
  <van-nav-bar :title="title" left-arrow @click-left="go" />
</template>

<script>
export default {
  props: ["title"],
  methods: {
    go() {
      if (window.history.length <= 1) {
        this.$router.push("/home");
        return;
      }
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped></style>