import api from '@/api/old_client_index'

import utils from '@/utils'
const state = {
  GameCategory: [],
  SymbleGameList: [],
  GameLobbyList: [],
  OpenHistoryList: [],
  OpenRecordList: [],
  chipList: [],
}
const mutations = {
  setGameCategory(state, data) {
    state.GameCategory = data
  },
  setSymbleGameList(state, data) {
    state.SymbleGameList = data
  },
  setGameLobbyList(state, data) {
    state.GameLobbyList = data
  },
  setOpenHistoryList(state, data) {
    state.OpenHistoryList = data
  },
  setOpenRecordList(state, data) {
    state.OpenRecordList = data
  },
  setChipList(state, data) {
    state.chipList = data
  },
}
const actions = {
  async getTransferRoadMap({ }) {
    const res = await api.game.transferGameLuzi()
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
  async getLotteryNext({ }, data) {
    const res = await api.game.lotteryNext(data)
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
  async getLotteryQuery({ }, data) {
    const res = await api.game.lotteryQuery(data)
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
  async getOpenHistory({ commit }, data) {
    const res = await api.game.openHistory(data)
    commit('setOpenHistoryList', res.data)
    commit('setOpenRecordList', res.data.slice(0, 30))
  },
  async gameBet({ }, data) {
    const res = await api.game.lotteryBet(data)
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
  async getPageBetRecorde({ }, data) {
    const res = await api.game.pageBetRecorde(data)
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
  async getRecordAbstract({ commit }, data) {
    const res = await api.game.recordAbstract(data)
    res.data.map(item => {
        item.winLose = utils.subtract(item.RewardAmount, item.Amount)
    })
    commit('setGameCategory', res.data)
  },
  async getBetRecord({ }, data) {
    const res = await api.game.betRecord(data)
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
  async getGameLobbyList({ }) {
    const res = await api.game.gameLobbyList()
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
  async getHashBetInfo({ }, data) {
    const res = await api.game.getHashBetInfo(data)
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
  async getChipsList({ commit }, data) {
    const res = await api.game.getChipsList(data)
    if (res.code === 200 && res.data.GameChip) {
      commit('setChipList', JSON.parse(res.data.GameChip))
      return Promise.resolve()
    }
  },
}
const getters = {
  CategoryTotal(state) {
    let totalInfo = {}
    totalInfo.TotalBetCount = state.GameCategory.map((row) => row.TotalBetCount).reduce((acc, cur) => utils.add(cur, acc), 0, 2)
    totalInfo.TotalAmount = state.GameCategory.map((row) => row.Amount).reduce((acc, cur) => utils.add(cur, acc), 0, 2)
    totalInfo.TotalRewardAmount = state.GameCategory.map((row) => row.RewardAmount).reduce((acc, cur) => utils.add(cur, acc), 0, 2)
    totalInfo.TotalWinLose = utils.subtract(totalInfo.TotalRewardAmount, totalInfo.TotalAmount, 2)
    if(totalInfo.TotalWinLose > 0) {
      totalInfo.TotalWinLose = `+${totalInfo.TotalWinLose}`
    }
    return totalInfo
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
