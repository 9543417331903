const domainList = [
  { url: 'okhash44.com', code: '1795215084287788' },
  { url: 'okhash45.com', code: '1438631520379069' },
  { url: 'clientview5.flea7900.vip', code: '1852881798487054' },
  { url: 'localhost', code: '1438631520379069' },
  { url: 'okhash98.com', code: '845728400793445' },
  { url: 'ok9.store', code: '1992652214522001' },
]

// 初始化
export const MatePixInit = function () {
  let currentDomain = top.location.href
  let matchDomain = domainList.find((item) => currentDomain.indexOf(item.url) != -1)
  if (matchDomain?.code) {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
    fbq('init', matchDomain.code)
    fbq('track', 'PageView')
  }
}
// 埋点事件
export const MatePixIncidentReport = function (index, money, id) {
  let currentDomain = top.location.href
  let matchDomain = domainList.find((item) => currentDomain.indexOf(item.url) != -1)
  if (matchDomain?.code) {
    if (index == 1) {
      window.fbq('track', 'CompleteRegistration', { eventID: id })
    } else {
      window.fbq('track', 'Purchase', { currency: 'USD', value: money }, { eventID: id })
    }
  }
}
