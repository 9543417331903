/*
 * @Description:
 * @Author: anji
 * @Date: 2021-11-10 15:24:37
 * @LastEditTime: 2021-11-11 00:26:15
 * @LastEditors: anji
 */
// import Vue from 'vue'
// import SvgIcon from '@/components/public/SvgIcon' // svg组件

// // 注册到全局
// Vue.component('svg-icon', SvgIcon)
// const files = require.context('./svg', false, /\.svg$/)
// files.keys().forEach(files)

import Vue from 'vue'
import SvgIcon from '@/components/public/SvgIcon' // svg组件

// 统一注册svg-icon组件
Vue.component('svg-icon', SvgIcon)

// 遍历require.context的返回模块，并导入
const requireAll = requireContext => requireContext.keys().map(requireContext)

// import所有符合条件的svg 三个参数：文件夹、是否使用子文件夹、正则
const req = require.context('@/assets/icons/svg', true, /\.svg$/)
requireAll(req)

