import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './locale/zh.json'
import en from './locale/en.json'
import th from './locale/th.json'
import ko from './locale/ko.json'
import tr from './locale/tr.json'
import vi from './locale/vi.json'
import pt from './locale/pt.json'
import hi from './locale/hi.json'
import ja from './locale/ja.json'
import ru from './locale/ru.json'
import km from './locale/km.json'
import uk from './locale/uk.json'
import zh_TW from './locale/zh-TW.json'

Vue.use(VueI18n)

const supportedLocales = ['zh', 'en', 'th', 'ko', 'tr', 'vi', 'pt', 'hi', 'ja', 'ru', 'km', 'uk', 'zh_TW']
let locale = localStorage.getItem('lang')?.replace('-', '_')
if (!locale) {
  locale =
    window.navigator.language == 'zh-TW' || window.navigator.language == 'zh-HK'
      ? 'zh_TW'
      : window.navigator.language.slice(0, 2)
}
if (!supportedLocales.includes(locale)) {
  locale = 'en' // 设置回退语言为英语
  localStorage.setItem('lang', locale?.replace('_', '-'))
}

const i18n = new VueI18n({
  messages: { zh, en, th, ko, tr, vi, pt, hi, ja, ru, km, uk, zh_TW },
  locale: locale,
  fallbackLocale: 'en',
  globalInjection: true,
  legacy: false,
})

export default i18n
