import { post, service } from '@/core/request'

// 获取数据
export const inviteFriends = params => post(`/agent/info_new`, params)
// 领取佣金
export const commissionNew = params => post(`/agent/get_commission_new`, params)
// 业绩查询
export const liusuiNew = params => post(`/agent/liusui_new`, params, { loading: false })
// 玩家查询
export const childSearch = params => post(`/agent/child_search`, params)
//获取佣金奖励
export const defineNew = params => post(`/agent/define_new`, params)
//佣金记录
export const commissionRecord = params => post(`/agent/commission_record_new`, params)
// 领取佣金
export const getCommissionNew = params => post(`/agent/get_commission_new_t1`, params,{ loading: false })
// 获取98代理我的佣金
export const myCommission98 = params => post(`/agent/info_t1`, params)
// 生成邀请链接
export const createCode98 = params => post(`/agent/create_code_t1`, params)
// 获取我的链接
export const myCode98 = params => post(`/agent/get_code_t1`, params,{ loading: false })
// 我的直属查询
export const commissionRecord98 = params => post(`/agent/dict_child_search_t1`, params,{ loading: false })
// 设置昵称
export const setAgentNickName98 = params => post(`/agent/set_agentnickname_t1`, params)
// 设置赔率
export const setFencheng98 = params => post(`/agent/set_fencheng_t1`, params)
// 我的直属下级查询
export const childSearch98 = params => post(`/agent/child_search_t1`, params)
// 修改备注
export const modifyRemark = params => post(`/agent/modify_code_t1`, params)
// 领取记录
export const commissionRecordNew = params => post(`/agent/commission_record_new_t1`, params,{ loading: false })
// 我的链接分成设置
export const linkCode = params => post(`/agent/update_code_t1`, params,{ loading: false })
// 佣金明细
export const commissionMine = params => post(`/agent/get_commission_mine_t1`, params,{ loading: false })



    


