import http from "@/request";
import { mergeBody } from "./util";

/**
 * @description 获取投注记录
 * @param {{Symbol: string, Page: number, PageSize: number, GameId: number}} payload
 * @return {Promise<{data: {Amount: number,BetArea: string,BlockHash: string,BlockNum: number,ChainType: number,CreateTime: string,GameId: number,Id: number,IsWin: number,Memo: string,NextBlockHash: string,OpenArea: string,Period: string,RewardAmount: 0,State: number,Symbol: string}[]}>}
 */
export function getBetRecord(payload) {
  return http.post("/api/game/bet_recordex", mergeBody(payload));
}

/**
 * @description  获取哈希投注信息
 * @param {{GameId: number, Period: string}} payload
 * @return {Promise<{data: {Amount: number,BetArea: string,BetCount: number,BetTime: string,BuyNum: string,ChainType: number,CreateTime: string,GameId: number,Hash: string,Id: number,IsWin: number,Memo: string,OpenArea: string,OpenNum: string,Period: string,RewardAmount: number,State: number,Symbol: string,UserId: number}[]}>}
 */
export function getHaxBetInfo(payload) {
  return http.post("/api/lottery/get_hash_bet_info", mergeBody(payload));
}

/**
 * @description  获取游戏列表
 * @return {Promise<{data: {Address: string,BackFeeRate: number,Chip: string,FeeRate: number,GameId: number,IsHot: number,IsNew: number,IsRecom: number,Lottery: null,OnlineCnt: number,OnlineMax: number,OnlineMin: number,PTrxLimitMax: number,PTrxLimitMin: number,PUsdtLimitMax: number,PUsdtLimitMin: number,RewardRate: number,RewardRateEx: string,RoomLevel: number,STrxLimitMax: number,STrxLimitMin: number,SUsdtLimitMax: number,SUsdtLimitMin: number,ShowOnline: number,TrxLimitMax: number,TrxLimitMaxHe: number,TrxLimitMin: number,TrxLimitMinHe: number,UsdtLimitMax: number,UsdtLimitMaxHe: number,UsdtLimitMin: number,UsdtLimitMinHe: number,}[]}>}
 */
export function getGameList() {
  return http.post("/api/game/list", mergeBody());
}

/**
 * @description 获取下注信息
*/
export function getChipsList() {
  return http.post("/api/lottery/get_config", mergeBody());
}

/**
 * @description 下注
 * @param {{ BetArea: string, Period: string, BetAmount: number, GameId: number, RoomLevel: number }} payload 
*/
export function bet(payload) {
  return http.post("/api/lottery/bet", mergeBody(payload));
}