import i18n from '@/lang'
// i18n.t('static.serviceList1')
// i18n.t('static.betAreaList1')
// i18n.t('static.selectDays1')
const state = {
  serviceList: [
    { title: 'static.serviceList1', width: '14.6px', height: '13.7px', label: 'CustomService', id: 1 },
    { title: 'static.serviceList3', width: '14.5px', height: '12.6px', label: 'CustomTelegram', id: 2 },
    { title: 'static.serviceList4', width: '14.6px', height: '14.5px', label: 'CustomWhatapp', id: 3 },
    { title: 'static.serviceList2', width: '14.6px', height: '14.1px', label: 'CustomTelegramGroup', id: 4 },
  ],
  betAreaList: {
    1: [
      { name: 'static.betAreaList1', ename: 'BIG', color: 'red' },
      { name: 'static.betAreaList2', ename: 'SMALL', color: 'yellow' },
    ],
    2: [
      { name: 'static.betAreaList3', ename: 'ODD', color: 'red' },
      { name: 'static.betAreaList4', ename: 'EVEN', color: 'yellow' },
    ],
    3: [{ name: 'HASH', ename: 'HASH', color: 'red' }],
    4: [
      { name: 'static.betAreaList5', ename: 'BANKER', color: 'red' },
      { name: 'static.betAreaList6', ename: 'TIE', color: 'green' },
      { name: 'static.betAreaList7', ename: 'PLAYER', color: 'yellow' },
    ],
    5: [{ name: 'static.betAreaList8', ename: 'BULL PLAYER', color: 'yellow' }],
  },
  selectDays: [
    { id: 1, label: 'static.selectDays1', days: 0 },
    { id: 2, label: 'static.selectDays2', days: 1 },
    { id: 3, label: 'static.selectDays3', days: 7 },
    { id: 4, label: 'static.selectDays3', days: 30 },
  ],
  selectCoin: [
    { id: 1, label: 'USDT', icon: require('@/assets/images/common/usdt-icon.webp') },
    { id: 2, label: 'TRX', icon: require('@/assets/images/common/trx-icon.webp') },
  ],
  walletList: [
    { icon: require('@/assets/images/detail/wallet-icon1.webp'), width: '75px', height: '12px', url: 'token.im', id: 1 },
    {
      icon: require('@/assets/images/detail/wallet-icon3.webp'),
      width: '89px',
      height: '12px',
      url: 'tronlink.org',
      id: 3,
    },
    {
      icon: require('@/assets/images/detail/wallet-icon2.webp'),
      width: '78px',
      height: '16px',
      url: 'tokenpocket.pro',
      id: 2,
    },
    {
      icon: require('@/assets/images/detail/wallet-icon5.webp'),
      width: '80px',
      height: '18px',
      url: 'trustwallet.com',
      id: 5,
    },
    {
      icon: require('@/assets/images/detail/wallet-icon4.webp'),
      width: '57px',
      height: '22px',
      url: 'bitpie.com',
      id: 4,
    },
    {
      icon: require('@/assets/images/detail/wallet-icon6.webp'),
      width: '68px',
      height: '14px',
      url: 'ownbit.com',
      id: 6,
    },
  ],
  videoLink: {
    1: {
      zh: {
        1: '/video/ok-daxiao.mp4',
        2: '/video/ok-danshuang.mp4',
        3: '/video/ok-xingyunhaxi.mp4',
        4: '/video/ok-zhuangxian.mp4',
        5: '/video/ok-niuniu.mp4',
        11: '/video/ok-hezhidaxiao.mp4',
        12: '/video/ok-hezhidanshuang.mp4',
      },
      en: {},
    },
  },
  langParam: { zh: 1, en: 2, th: 3, ko: 4, tr: 5, vi: 6, pt: 7, hi: 8, ja: 9, ru: 10, km: 11, uk: 12, 'zh-TW': 13 },
  gameRoadTitle: {
    1: { 1: 'B', 2: 'S' },
    2: { 1: 'O', 2: 'E' },
    3: { 1: 'W', 2: 'L' },
    4: { 1: 'V', 2: 'I', 3: 'T' },
    5: { 1: 'V', 2: 'I', 3: 'T' },
    11: { 1: 'B', 2: 'S' },
    12: { 1: 'O', 2: 'E' },
  },
  gameRoadTitleZh: {
    1: { 1: '大', 2: '小' },
    2: { 1: '单', 2: '双' },
    3: { 1: '中', 2: '未' },
    4: { 1: '庄', 2: '闲', 3: '和' },
    5: { 1: '庄', 2: '闲', 3: '和' },
    11: { 1: '大', 2: '小' },
    12: { 1: '单', 2: '双' },
  },
  // home导航索引
  defMainNavIndex: {
    home: 0, // 首页
    hash: 2, // 哈希
    dianzi: 3, // 电子
    xiaoyouxi: 4, // 小游戏(区块链)(加密游戏)
    live: 5, // 真人
    qipai: 6, // 棋牌
    sport: 7, // 体育
    poker: 8, // 德州
    trading: 9, // 秒合约
    updown: 10, // 涨跌
    lottery: 11, // 彩票
  },
}
const mutations = {}
const actions = {}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
