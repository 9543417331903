<template>
  <div class="page">
    <NavBar v-show="showNav" :title="title" ref="navBarRef"/>
    <main>
      <div class="main-content">
        <slot />

      </div>
    </main>
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
export default {
  props: {
    title: {
      type: String
    },
    showNav: {
      type: Boolean,
      default: true
    }
  },
  components: {
    NavBar
  },
  methods: {
    back() {
      this.$refs.navBarRef?.go();
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;

  main {
    flex: 1;
    min-height: 0;
    overflow: hidden;
    overflow-y: auto;
    .main-content {
      padding: 15px;
      box-sizing: border-box;
    } 
  }
}
</style>