import { post, service } from '@/core/request'

// 三方彩票授权登录
export const lotteryLogin = (params) => post(`${service.third}/gfg_hash_single_login`, params, { loading: false })

// 三方棋牌授权登录
export const chessLogin = (params) => post(`${service.third}/gfg_single_login`, params, { loading: false })
export const gfgLogin = (params) => post(`${service.third}/gfg_single_login`, params, { loading: false })

// 三方区块链游戏授权登录
export const xyxLogin = (params) => post(`${service.third}/xiaoyouxi_login`, params, { loading: false })

// 三方PG电子授权登录
export const pgLogin = (params) => post(`${service.third}/pg_single_login`, params, { loading: false })

// 三方PP电子授权登录
export const ppLogin = (params) => post(`${service.third}/pp_single_login`, params, { loading: false })

// 三方EVO视讯授权登录
export const evoLogin = (params) => post(`${service.third}/evo_single_login`, params, { loading: false })

// 三方hub88授权登录
export const hub88Login = (params) => post(`${service.third}/hub_single_login`, params, { loading: false })

// 三方WM视讯授权登录
export const wmLogin = (params) => post(`${service.third}/mw_login`, params, { loading: false })

// 三方AG视讯授权登录
export const agLogin = (params) => post(`${service.third}/ag_single_login`, params, { loading: false })

// 三方Astar视讯授权登录
export const astarLogin = (params) => post(`${service.third}/astar_login`, params, { loading: false })

// 三方UP体育授权登录
export const upLogin = (params) => post(`${service.third}/up_login`, params, { loading: false })

// 三方easybet体育登录授权
export const easybetLogin = (params) => post(`${service.third}/easybetsp_login`, params, { loading: false })

// 三方三昇体育登录授权
export const three_upLogin = (params) => post(`${service.third}/threeup_login`, params, { loading: false })

// 三方德州扑克登录授权
export const idnLogin = (params) => post(`${service.third}/idn_login`, params, { loading: false })

// SPRIBE区块链游戏登录授权
export const spribeLogin = (params) => post(`${service.third}/jdb_login`, params, { loading: false })

// 无双区块链游戏OG登录授权
export const ogLogin = (params) => post(`${service.third}/og_login`, params, { loading: false })

// 三方余额刷新
export const syncAmount = (params) => post(`${service.third}/sync_amount`, params, { loading: false })

// 三方支付-币种列表
export const symbolList = (params) => post(`pay/get_symbol`, params)

// 三方支付-支付渠道
export const channelList = (params) => post(`/pay/get_pay_info`, params, { loading: false })

// 三方支付-充提
export const createOrder = (params) => post(`/pay/create_order`, params, { loading: false })
// 获取冲提汇率
export const chainRate = (params) => post(`/pay/get_chain_rate`, params, { loading: false })

// transak充值
export const transakOrder = (params) => post('/transak/create_order', params, { loading: false })

// transak国家货币
export const transakCountries = (params) => post('/transak/countries', params, { loading: false })
//加密货币信息
export const GetList = (params) => post(`${service.third}/GetList`, params, { loading: false })

// 赛事推荐
export const getEasybetspHotEvents = (params) =>
  post(`${service.third}/easybetsp_getfootballhotevents`, params, { loading: false })
