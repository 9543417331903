import http from "../request";
import { mergeBody } from "./util";

/**
 * @description 获取提现或充值信息
 * @param {{ RechargeWithdraw: number }} payload  1:充值 2:提现
 * @return {Promise<{ data: {"BankType": string,"Banks": string, "Brand": string,"HasBankNoRecharge": number,"Icon": string,"Id": number,"IsBankRecharge": number, "IsInputWithdrawAmount": number, "MaxWithdraw": number,"MinWithdraw": number,"Name": string,"NetJson": "[{\"Net\":\"Bitcoin\",\"Chain\":\"btc\",\"TradeTime\":\"11\",\"State\":1}]","PayType": null,"PoundSign": "$","Rate": 63470,"RechargeWayType": 1,"State": 1,"Symbol": "btc","WithdrawAmountOptions": "[\"\"]","WithdrawBanks": null,"WithdrawWayType": 1}[] }>}
 */
export function getPayInfo(payload) {
  return http.post("/api/pay/get_pay_info", mergeBody(payload));
}

/**
 * @description 获取提现配置
 * @return {Promise<{data:{FeeFreeCountPerDay: number,FeeFreeQuotaPerDay: number,FeePercent: number,MaxAmountPerDay: number,MaxCountPerDay: number,TodayWithdrawAmount: number,TodayWithdrawCount: number}}>}
 */
export function getWithdrawConfig() {
  return http.post("/api/user/get_withdraw_config", mergeBody());
}

/**
 * @description 获取币种信息
 *
 */
export function getSymbolList() {
  return http.post("/api/pay/get_symbol", mergeBody({ Sort: "desc", Show: 1 }));
}

/**
 * @description 提现地址
 * @return {Promise<{ data: {Address: string,CreateTime: string,Memo: string,Net: string,State: number,UserId: number}[] }>}
 */
export function getWithdrawAddress() {
  return http.post("/api/user/get_withdraw_address", mergeBody());
}

/**
 * @description 添加提现地址
 * @param {{Address: string,Memo: string, Net: string}} payload 
*/
export function addWithdrawAddress(payload) {
  return http.post("/api/user/add_withdraw_address", mergeBody(payload));
}

/**
 * @description 提现
 * @param {{ Address: string,Amount: number,Net: string,Password: string }} payload
 */
export function withdraw(payload) {
  return http.post("/api/user/withdraw_v2", mergeBody(payload));
}

/**
 * @description 币种利率
 * @param {{Symbol: string}} payload
 * @return {Promise<{ data: {MaxWithdraw: number,MinRecharge: number,MinWithdraw: number,Rate: number} }>}
 */
export function getSymbolRate(payload) {
  return http.post("/api/pay/get_chain_rate", mergeBody(payload));
}

/**
 * @description 获取用户余额
 * @return {Promise<{ data: { Amount: number } }>}
 */
export function getBalance() {
  return http.post("/api/user/get_balance", mergeBody());
}
/**
 * @description  设置资金密码
 * @return {Promise<{ data: { Amount: number } }>}
 */
export function password(payload) {
  return http.post("/api/user/set_wallet_password", mergeBody(payload));
}


/**
 * @description 获取充值地址
 * @param {{Chain: string}} payload
 * @return {Promise<{ data: { address: string, memo: string} }>}
*/
export function getRechargeAddress(payload) {
  return http.post("/api/user/get_recharge_address_v2", mergeBody(payload));
}
