import i18n from '@/lang'
import utils from '@/utils'
// i18n.t('gameList1')
const gameList = [
  { id: 1, name: 'textFilter.gameList1' },
  { id: 2, name: 'textFilter.gameList2' },
  { id: 3, name: 'textFilter.gameList3' },
  { id: 4, name: 'textFilter.gameList4' },
  { id: 5, name: 'textFilter.gameList5' },
  { id: 6, name: 'textFilter.gameList6' },
  { id: 7, name: 'textFilter.gameList7' },
  { id: 11, name: 'textFilter.gameList9' },
  { id: 12, name: 'textFilter.gameList8' },
  { id: 101, name: 'textFilter.gameList10' },
  { id: 102, name: 'textFilter.gameList11' },
  { id: 103, name: 'textFilter.gameList12' },
  { id: 104, name: 'textFilter.gameList13' },
  { id: 105, name: 'textFilter.gameList14' },
  { id: 131, name: 'textFilter.gameList20' },
  { id: 132, name: 'textFilter.gameList21' },
  { id: 133, name: 'textFilter.gameList22' },
  { id: 134, name: 'textFilter.gameList23' },
  { id: 135, name: 'textFilter.gameList24' },
  { id: 201, name: 'textFilter.gameList15' },
  { id: 202, name: 'textFilter.gameList16' },
  { id: 203, name: 'textFilter.gameList17' },
  { id: 204, name: 'textFilter.gameList18' },
  { id: 205, name: 'textFilter.gameList19' },
  { id: 301, name: 'textFilter.gameList10' },
  { id: 302, name: 'textFilter.gameList11' },
  { id: 331, name: 'textFilter.gameList20' },
  { id: 332, name: 'textFilter.gameList21' },
]
// i18n.t('activeList1')
const activeList = [
  { id: 1, name: 'textFilter.activeList1' },
  { id: 2, name: 'textFilter.activeList2' },
  { id: 3, name: 'textFilter.activeList3' },
  { id: 4, name: 'textFilter.activeList4' },
  { id: 5, name: 'textFilter.activeList5' },
  { id: 6, name: 'textFilter.activeList6' },
  { id: 7, name: 'textFilter.activeList7' },
  { id: 8, name: 'textFilter.activeList8' },
  { id: 9, name: 'textFilter.activeList9' },
  { id: 10, name: 'textFilter.activeList10' },
  { id: 11, name: 'textFilter.activeList11' },
  { id: 12, name: 'textFilter.activeList12' },
  { id: 13, name: 'textFilter.activeList13' },
  { id: 14, name: 'textFilter.activeList14' },
  { id: 15, name: 'textFilter.activeList15' },
  { id: 16, name: 'textFilter.activeList16' },
  { id: 54, name: 'textFilter.activeList54' },
  { id: 55, name: 'textFilter.activeList55' },
  { id: 56, name: 'textFilter.activeList56' },
  { id: 57, name: 'textFilter.activeList57' },
  { id: 58, name: 'textFilter.activeList58' },
  { id: 59, name: 'textFilter.activeList59' },
  { id: 60, name: 'textFilter.activeList60' },
  { id: 61, name: 'textFilter.activeList61' },
  { id: 62, name: 'textFilter.activeList62' },
  { id: 63, name: 'textFilter.activeList63' },
  { id: 64, name: 'textFilter.activeList64' },
  { id: 65, name: 'textFilter.activeList65' },
  { id: 66, name: 'textFilter.activeList66' },
  { id: 67, name: 'textFilter.activeList67' },
  { id: 68, name: 'textFilter.activeList68' },
  { id: 69, name: 'textFilter.activeList69' },
  { id: 70, name: 'textFilter.activeList70' },
  { id: 71, name: 'textFilter.activeList71' },
  { id: 100, name: 'textFilter.activeList100' },
]
// i18n.t('statuList1')
const statuList = [
  { id: 1, name: 'textFilter.statuList1' },
  { id: 2, name: 'textFilter.statuList2' },
  { id: 3, name: 'textFilter.statuList3' },
  { id: 4, name: 'textFilter.statuList4' },
]
// i18n.t('bonusList1')
const bonusList = [
  { id: 1, name: 'textFilter.bonusList1' },
  { id: 2, name: 'textFilter.bonusList2' },
  { id: 3, name: 'textFilter.bonusList3' },
  { id: 4, name: 'textFilter.bonusList4' },
  { id: 5, name: 'textFilter.bonusList5' },
  { id: 6, name: 'textFilter.bonusList6' },
  { id: 7, name: 'textFilter.bonusList7' },
  { id: 8, name: 'textFilter.bonusList8' },
  { id: 9, name: 'textFilter.bonusList9' },
  { id: 10, name: 'textFilter.bonusList10' },
  { id: 11, name: 'textFilter.bonusList11' },
  { id: 12, name: 'textFilter.bonusList12' },
  { id: 52, name: 'textFilter.bonusList52' },
  { id: 53, name: 'textFilter.bonusList53' },
  { id: 54, name: 'textFilter.bonusList54' },
  { id: 55, name: 'textFilter.bonusList55' },
  { id: 56, name: 'textFilter.bonusList56' },
  { id: 57, name: 'textFilter.bonusList57' },
  { id: 58, name: 'textFilter.bonusList58' },
  { id: 59, name: 'textFilter.bonusList59' },
  { id: 60, name: 'textFilter.bonusList60' },
  { id: 201, name: 'textFilter.bonusList13' },
  { id: 221, name: 'textFilter.bonusList14' },
  { id: 222, name: 'textFilter.bonusList15' },
  { id: 223, name: 'textFilter.bonusList16' },
  { id: 224, name: 'textFilter.bonusList17' },
  { id: 225, name: 'textFilter.bonusList18' },
  { id: 227, name: 'textFilter.bonusList19' },
  { id: 228, name: 'textFilter.bonusList20' },
  { id: 229, name: 'textFilter.bonusList21' },
  { id: 230, name: 'textFilter.bonusList22' },
  { id: 231, name: 'textFilter.bonusList23' },
  { id: 232, name: 'textFilter.bonusList24' },
  { id: 233, name: 'textFilter.bonusList25' },
  { id: 266, name: 'textFilter.bonusList26' },
  { id: 267, name: 'textFilter.bonusList27' },
  { id: 268, name: 'textFilter.bonusList28' },
  { id: 269, name: 'textFilter.bonusList29' },
  { id: 270, name: 'textFilter.activeList6' },
  { id: 271, name: 'textFilter.bonusList30' },
  { id: 280, name: 'textFilter.bonusList280' },
  { id: 281, name: 'textFilter.bonusList281' },
  { id: 282, name: 'textFilter.bonusList282' },
  { id: 283, name: 'textFilter.bonusList283' },
  { id: 284, name: 'textFilter.bonusList284' },
]

const brandList = [
  { id: 'gfg', name: 'GFG' },
  { id: 'pg', name: 'PG SOFT' },
  { id: 'pp', name: 'Pragmatic Play' },
  { id: 'ag', name: 'AG LIVE' },
  { id: 'wm', name: 'WM LIVE' },
  { id: 'evo', name: 'EVO LIVE' },
  { id: 'astar', name: 'ASTAR LIVE' },
  { id: 'up', name: 'ULTRAPLAY' },
  { id: 'easybet', name: 'EASYBET' },
  { id: 'three_up', name: '3 SING' },
  { id: 'xyx', name: 'T1 Gaming' },
  { id: 'spribe', name: 'SPRIBE' },
  { id: 'og', name: 'OG GAME' },
  { id: 'hub88_OneTouch_Generic', name: 'OneTouch Generic' },
  { id: 'hub88_Kalamba', name: 'Kalamba' },
  { id: 'hub88_Hacksaw_Gaming', name: 'Hacksaw Gaming' },
  { id: 'hub88_Nolimit_City', name: 'Nolimit City' },
  { id: 'hub88_AvatarUX', name: 'AvatarUX' },
  { id: 'bbl', name: 'Bombay Live' },
  { id: 'hub88_OneTouch_Table_game', name: 'OneTouch Table' },
  { id: 'bpc', name: '低频彩' },
  { id: 'ssc', name: '时时彩' },
  { id: 'ttjsc', name: '天天极速彩' },
  { id: 'xxx', name: 'STAY TUNED' },
]

const langList = [
  { text: '中文', code: 'zh' },
  { text: 'English', code: 'en' },
  { text: 'ภาษาไทย', code: 'th' },
  { text: '한국인', code: 'ko' },
  { text: 'Türkçe', code: 'tr' },
  { text: 'Tiếng Việt', code: 'vi' },
  { text: 'Portugal', code: 'pt' },
  { text: 'हिन्दी', code: 'hi' },
  { text: '日本語', code: 'ja' },
  { text: 'Руccкий', code: 'ru' },
  { text: 'កម្ពុជា។', code: 'km' },
  { text: 'українська мова', code: 'uk' },
  { text: '繁體', code: 'zh-TW' },
]

export function Rname(id) {
  return i18n.t(gameList.find((item) => id == item.id).name, { L: utils.logo() })
}

export function Rstatu(id) {
  return i18n.t(statuList.find((item) => id == item.id).name)
}

export function Ractivename(id) {
  return i18n.t(activeList.find((item) => id == item.id).name, { L: utils.logo() })
}

export function Rbonus(id) {
  return i18n.t(bonusList.find((item) => id == item.id)?.name)
}

export function RbrandName(id) {
  return brandList.find((item) => id == item.id).name
}

export function Rlang(code) {
  return langList.find((item) => code == item.code)?.text
}
