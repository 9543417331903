import api from '@/api/old_client_index'

import utils from '@/utils'
const state = {
  userInfo: {},
  serviceList: {},
  balance: '',
  walletAddress: [],
  VipDefine: [],
  VipInfo: {},
  WinLossReport: {},
  WithdrawAddr: [],
  PersonalOdds: '',
}
const mutations = {
  setUserInfo(state, data) {
    state.userInfo = data
  },
  setServiceList(state, data) {
    state.serviceList = data
  },
  setBalance(state, data) {
    state.balance = data
    this.commit("userStore/setInfo", { ...this.state.userStore.userInfo, Amount: data })
  },
  setWalletAddress(state, data) {
    state.walletAddress = data
  },
  setVipDefine(state, data) {
    state.VipDefine = data
  },
  setVipInfo(state, data) {
    state.VipInfo = data
  },
  setWinLossReport(state, data) {
    state.WinLossReport = data
  },
  setWithdrawAddr(state, data) {
    data.forEach((item) => {
      item.label = item.Address
    })
    state.WithdrawAddr = data
  },
  setPersonalOdds(state, data) {
    state.PersonalOdds = data
  },
}
const actions = {
  async userLogin({}, user) {
    const res = await api.user.login(user)
    if (res.code === 200) {
      // 登录成功
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async userRegist({}, user) {
    const res = await api.user.regist(user)
    if (res.code === 200) {
      // 注册成功
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async userLogout({}) {
    const res = await api.user.logout()
    if (res.code === 200) {
      // 退出登录
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getUserInfo({ commit }, pParams) {
    if (!pParams) {
      pParams = { IsLoading: true }
    }
    //获取个人信息
    const res = await api.user.userInfo(null, pParams.IsLoading)
    if (res.code === 200) {
      commit('setUserInfo', res.data)
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      return Promise.resolve(res.data)
    }
  },
  async getBalance({ commit }) {
    //获取余额
    if (!localStorage.getItem('token')) return
    const res = await api.user.getBalance()
    if (res.code === 200) {
      commit('setBalance', res.data.Amount)
    }
  },
  async getCustomService({ commit }) {
    //获取客服链接
    let lang = localStorage.getItem('lang') || 'zh'
    let langParam = {
      zh: 1,
      en: 2,
      th: 3,
      ko: 4,
      tr: 5,
      vi: 6,
      pt: 7,
      hi: 8,
      ja: 9,
      ru: 10,
      km: 11,
    }
    let LangId = langParam[lang]
    const data = {
      LangId,
    }
    const res = await api.user.customService(data)
    if (res.code === 200) {
      commit('setServiceList', res.data)
      return Promise.resolve(res.data)
    }
  },
  async getWalletAddress({ commit }) {
    //获取钱包地址
    const res = await api.user.getWalletAddress()
    if (res.code === 200) {
      commit('setWalletAddress', res.data)
      return Promise.resolve(res.data)
    }
  },
  async addWalletAddress({}, data) {
    //添加钱包地址
    const res = await api.user.addWalletAddress(data)
    if (res.code === 200) {
      return Promise.resolve(res)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async setUserInfo({}, data) {
    //设置个人信息
    const res = await api.user.setUserInfo(data)
    if (res.code === 200) {
      // 设置成功
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getGamePeilv({ commit }, data) {
    //设置个人赔率
    const res = await api.user.gamePeilv(data)
    if (res.code === 200) {
      if (res.data.GameFee) {
        commit('setPersonalOdds', JSON.parse(res.data.GameFee))
      }
    }
  },
  async getVipInfo({ commit }) {
    //vip列表
    const res = await api.user.vipInfo()
    if (res.code === 200) {
      commit('setVipDefine', res.data.vip_define)
      commit('setVipInfo', res.data.vip_info)
    }
  },
  async getWinLossReport({ commit }, data) {
    //输赢报表
    const res = await api.user.winLossReport(data)
    if (res.code === 200) {
      commit('setWinLossReport', res.data)
    }
  },
  async getRecRecharge({}, data) {
    //交易记录-充值
    const res = await api.user.recRecharge(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getRecWithdraw({}, data) {
    //交易记录-提现
    const res = await api.user.recWithdraw(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getRecTransfer({}, data) {
    //交易记录-转账
    const res = await api.user.recTransfer(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getRecCaijin({}, data) {
    //交易记录-红利
    const res = await api.user.reCaijin(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getWithdraw({}, data) {
    //提现
    const res = await api.user.withdraw(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getWithdrawAddr({ commit }) {
    //提现地址列表
    const res = await api.user.withdrawAddr()
    if (res.code === 200) {
      commit('setWithdrawAddr', res.data)
    }
  },
  async getDelWithdrawAddr({}, data) {
    //删除提现地址
    const res = await api.user.delWithdrawAddr(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getAddWithdrawAddr({}, data) {
    //添加提现地址
    const res = await api.user.addWithdrawAddr(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getBindPhone({}, data) {
    //绑定电话
    const res = await api.user.bindPhone(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getModLoginPwd({}, data) {
    //修改登录密码
    const res = await api.user.modLoginPwd(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getSetFundPwd({}, data) {
    //设置资金密码
    const res = await api.user.setFundPwd(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getTrialFee({}, data) {
    //领取体验金
    const res = await api.user.TrialFee(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async changeUserChips({}, data) {
    const res = await api.user.changeUserChips(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
}
const getters = {
  avtivedAddressNum(state) {
    return state.walletAddress.filter((item) => item.State === 1).length
  },
  vipInfoData(state) {
    let vip_info_data = {
      level: state.VipInfo.VipLevel,
      currenRecharge: 0, // 当前等级充值金额
      currenLiuSui: 0, // 当前等级流水
      rechargeRate: 0, // 充值金额比例
      liuSuiRate: 0, // 流水金额比例
      vipProgress: 0, // vip进度条
      diffRecharge: 0, // 距离下个等级充值金额差值
      diffLiuSui: 0, // 距离下个等级流水金额差值
      keepLiuSuiProgress: 0, // 保级流水进度条
    }
    let vip_info = state.VipInfo || {} // 该账号vip信息
    let cur_level_info = {
      LiuSui: 0,
      KeepLiuSui: 0,
      Recharge: 0,
    } // 当前等级信息
    let next_level_info = {
      LiuSui: 0,
      KeepLiuSui: 0,
      Recharge: 0,
    } // 下个等级信息
    if (vip_info.VipLevel > 0) {
      cur_level_info = state.VipDefine.find((item) => vip_info.VipLevel === item.VipLevel)
    }
    try {
      if (vip_info.VipLevel < state.VipDefine.length || vip_info.VipLevel == 0) {
        next_level_info = state.VipDefine.find((item) => vip_info.VipLevel + 1 === item.VipLevel)
        if (vip_info.VipLevel == 0) {
          vip_info_data.diffRecharge = next_level_info.Recharge
          vip_info_data.diffLiuSui = next_level_info.LiuSui
        } else {
          vip_info_data.diffRecharge = utils.subtract(next_level_info.Recharge, cur_level_info.Recharge, 2)
          vip_info_data.diffLiuSui = utils.subtract(next_level_info.LiuSui, cur_level_info.LiuSui, 2)
        }
        vip_info_data.currenRecharge =
          utils.subtract(vip_info.Recharge, cur_level_info.Recharge, 2) >= vip_info_data.diffRecharge
            ? vip_info_data.diffRecharge
            : utils.subtract(vip_info.Recharge, cur_level_info.Recharge, 2)
        vip_info_data.currenLiuSui =
          utils.subtract(vip_info.LiuSui, cur_level_info.LiuSui, 2) >= vip_info_data.diffLiuSui
            ? vip_info_data.diffLiuSui
            : utils.subtract(vip_info.LiuSui, cur_level_info.LiuSui, 2)
        vip_info_data.rechargeRate = utils.devide(vip_info_data.currenRecharge, vip_info_data.diffRecharge, 2)
        vip_info_data.liuSuiRate = utils.devide(vip_info_data.currenLiuSui, vip_info_data.diffLiuSui, 2)
        if (vip_info_data.rechargeRate < vip_info_data.liuSuiRate) {
          // 取比例最小的显示
          vip_info_data.vipProgress = utils.multiply(vip_info_data.rechargeRate, 100, 2)
          vip_info_data.vipProgress = vip_info_data.vipProgress < 0 ? 0 : vip_info_data.vipProgress
        } else {
          vip_info_data.vipProgress = utils.multiply(vip_info_data.liuSuiRate, 100, 2)
          vip_info_data.vipProgress = vip_info_data.vipProgress < 0 ? 0 : vip_info_data.vipProgress
        }
      } else {
        vip_info_data.vipProgress = 100
      }
      vip_info_data.keepLiuSuiProgress =
        utils.devide(vip_info.KeepLiuSui, cur_level_info.KeepLiuSui, 2) > 1
          ? 100
          : utils.multiply(utils.devide(vip_info.KeepLiuSui, cur_level_info.KeepLiuSui, 2), 100, 2)
    } catch (error) {
      console.warn('vip ', error)
    }
    return vip_info_data
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
