import { post, service } from '@/core/request'

// 首页轮播图
export const homeCarouselList = (params) => post(`${service.active}/home_carousel_list`, params)

// 跑马灯
export const marquee = (params) => post(`${service.active}/queuedmsg`, params)

// 活动列表
export const activeDefine = (params) => post(`${service.active}/active_define`, params)

// 新活动详情
export const activeInfo = (params) => post(`${service.active}/active_info`, params)
// 新活动
export const activeInfoNew = (params, url) => post(`${service.active}/${url}`, params)
// 活动详情_user
export const activeInfoUser = (params) => post(`${service.active}/active_info_user`, params, { loading: false })

// 活动领奖记录
export const activeRecord = (params) => post(`${service.active}/active_reward_list`, params, { loading: false })

// 每周大派送--立即申请
export const activeAwardApply = (params) => post(`${service.active}/active_apply`, params, { loading: false })

// 转盘抽奖
export const spinWithward = (params) => post(`${service.active}/spin_withward`, params, { loading: false })

// 转盘分享名单
export const spinShareList = (params) => post(`${service.active}/spin_sharelist`, params, { loading: false })
