/*
 * @Description: get 、 post 封装 , service 管理
 * @Author: anji
 * @Date: 2021-11-20 13:21:06
 * @LastEditTime: 2021-11-21 00:58:37
 * @LastEditors: anji
 */

/**
 * 满足需求：
 * 1. 请求响应拦截
 * 2. 路由跳转取消请求
 * 3. 去重重复请求
 */
import http from './lib/axios'

/**
 * 接口域名的管理
 */
export const service = {
  user: 'user', // 用户
  app: 'app', // 站点信息
  game: 'game', // 游戏
  agent: 'agent', // 代理
  active: 'active', // 活动
  third: 'third', // 三方游戏
  lottery: 'lottery',//彩票
}

/**
 * get方法
 * @param {String} url 请求的url地址
 * @param {Object} params 请求时携带的参数
 * @param {Object} config 额外请求配置参数，便于拦截器里使用
 */
export function get(url, params = { SellerId: 1 }, config = { loading: true }) {
  return new Promise((resolve, reject) => {
    http
      .get(url, { params, ...config })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/**
 * post方法
 * @param {String} url 请求的url地址
 * @param {Object} params 请求时携带的参数
 * @param {Object} config 额外请求配置参数，便于拦截器里使用
 */
export function post(url, data = {}, config = { loading: true }) {
  return new Promise((resolve, reject) => {
    http
      .post(url, { ...data, SellerId: 1 }, config)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
