import { getChipsList, getGameList } from "@/api/game";
import { isArray } from "lodash-es";

const getInitialState = () => ({
  gameList: [],
  chipsList: [],
});

export default {
  namespaced: true,
  state: () => getInitialState(),
  mutations: {
    setGameList(state, payload) {
      state.gameList = payload;
    },
    setChipsList(state, payload) {
      state.chipsList = payload;
    },
    resetStore(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getList({ commit }) {
      return getGameList().then((res) => {
        const list = isArray(res.data) ? res.data : [];
        commit("setGameList", list);
        return list;
      });
    },
    async getChipsList({ commit }) {
      return getChipsList().then((res) => {
        const list = res.data.GameChip ? JSON.parse(res.data.GameChip) : [{ chip:1, select: 1 }, { chip:5, select: 1 }, { chip:10, select: 1 }];
        commit("setChipsList", list);
        return list;
      });
    },
  },
};
