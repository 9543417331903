/*
 * @Description: dom 扩展
 * @Author: anji
 * @Date: 2021-11-04 20:46:37
 * @LastEditTime: 2021-11-19 11:25:54
 * @LastEditors: anji
 */
import i18n from '@/lang'
import { Toast } from 'vant'

export const H5_version = '0.0.1'

export const copyText = function (text) {
  const input = document.createElement('input')
  input.setAttribute('readonly', 'readonly') // 解决点击复制时，屏幕下方会出现白屏抖动问题
  input.setAttribute('value', text)
  document.body.appendChild(input)
  input.select()

  if (document.execCommand('copy')) {
    document.execCommand('copy')
    success(i18n.t('common.copySucc'))
    document.body.removeChild(input)
  } else {
    fail(i18n.t('common.copyFail'))
    document.body.removeChild(input)
  }
}

export const logoutRemoveItem = function () {
  localStorage.removeItem('giftPop' + localStorage.getItem('UserId'))
  localStorage.removeItem('token')
  localStorage.removeItem('VerifyAddress')
  localStorage.removeItem('UserId')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('activedWallte')
}

export const truncateDecimals = function (number, decimalPlaces) {
  // 保留2位小数，截断多余部分
  const multiplier = Math.pow(10, decimalPlaces)
  return Math.floor(number * multiplier) / multiplier
}

export const isPhone = function () {
  return /iphone|ipod|android|windows.*phone|blackberry.*mobile/i.test(window.navigator.userAgent.toLowerCase())
}

/**
 * Toast 成功提示
 */
export const success = (msg) => {
  Toast.success({
    message: msg,
    icon: require('@/assets/images/common/success.webp'),
  })
}

/**
 * Toast 失败提示
 */
export const fail = (msg) => {
  Toast.fail({
    message: msg,
    icon: require('@/assets/images/common/fail.webp'),
  })
}
