/*
 * @Description: msg提示中文国际化
 * @Author: anji
 * @Date: 2021-11-04 20:46:37
 * @LastEditTime: 2021-11-19 11:25:54
 * @LastEditors: anji
 */
import store from '@/store'
export const logo = () => {
  return sellerOk() ? 'OK' : '98'
}

export const logoName = () => {
  return sellerOk() ? 'OK fun' : '98 Hash'
}

export const agentOk = () => {
  return store.state.app.appInfo.IsAgent == 2
}

export const agent98 = () => {
  return store.state.app.appInfo.IsAgent == 1
}

export const agent = () => {
  return store.state.app.appInfo.IsAgent
}

export const sellerOk = () => {
  return store.state.app.appInfo.SellerId == 1
}

export const seller98 = () => {
  return store.state.app.appInfo.SellerId == 2
}

export const seller = () => {
  return store.state.app.appInfo.SellerId
}

export const matchResMsg = (errmsg) => {
  const errmsgList = [
    {
      msg: '账号不存在',
      lang: 'match.word1',
    },
    {
      msg: '密码不正确',
      lang: 'match.word2',
    },
    {
      msg: '未登录或登录已过期',
      lang: 'match.word3',
    },
    {
      msg: '账号已经存在',
      lang: 'match.word4',
    },
    {
      msg: '邀请码不存在',
      lang: 'match.word5',
    },
    {
      msg: '地址已注册',
      lang: 'match.word6',
    },
    {
      msg: '没有可领取的佣金',
      lang: 'match.word7',
    },
    {
      msg: '领取订单审核中,请勿重复申请',
      lang: 'match.word8',
    },
    {
      msg: '金额大于最大兑换数量',
      lang: 'match.word9',
    },
    {
      msg: '金额小于最小兑换数量',
      lang: 'match.word10',
    },
    {
      msg: '分成比例不可小于当前设定',
      lang: 'match.word54',
    },
    {
      msg: '分成比例不可大于当前设定',
      lang: 'match.word11',
    },
    {
      msg: '分成比例不可小于最小限制',
      lang: 'match.word12',
    },
    {
      msg: '分成比例不可大于最大限制',
      lang: 'match.word13',
    },
    {
      msg: '操作频繁,请求稍后再试',
      lang: 'match.word14',
    },
    {
      msg: '今日已申请',
      lang: 'match.word15',
    },
    {
      msg: '已申请',
      lang: 'match.word16',
    },
    {
      msg: '活动不存在',
      lang: 'match.word17',
    },
    {
      msg: '进入失败,请稍后再试',
      lang: 'match.word18',
    },
    {
      msg: '操作频繁,请稍后再试',
      lang: 'match.word19',
    },
    {
      msg: '每日00:30:00以后才可领取佣金',
      lang: 'match.word55',
    },
    {
      msg: '验证码不正确',
      lang: 'match.word20',
    },
    {
      msg: '手机号已绑定',
      lang: 'match.word21',
    },
    {
      msg: '验证码不存在',
      lang: 'match.word22',
    },
    {
      msg: '下注成功',
      lang: 'match.word23',
    },
    {
      msg: '本期下注已截止',
      lang: 'match.word24',
    },
    {
      msg: '地址已绑定',
      lang: 'match.word25',
    },
    {
      msg: '原登录密码不正确',
      lang: 'match.word26',
    },
    {
      msg: '流水不足,无法提款',
      lang: 'match.word27',
    },
    {
      msg: '提款金额不可少于手续费',
      lang: 'match.word28',
    },
    {
      msg: '提交成功,请等待审核',
      lang: 'match.word29',
    },
    {
      msg: '金额不足',
      lang: 'match.word30',
    },
    {
      msg: '提款小于最低限制',
      lang: 'match.word31',
    },
    {
      msg: '玩家不存在',
      lang: 'match.word32',
    },
    {
      msg: '地址不正确',
      lang: 'match.word33',
    },
    {
      msg: '添加失败,请稍后再试',
      lang: 'match.word34',
    },
    {
      msg: '添加失败,该地址已添加',
      lang: 'match.word35',
    },
    {
      msg: '您被禁止领取佣金',
      lang: 'match.word36',
    },
    {
      msg: '提款密码不正确',
      lang: 'match.word37',
    },
    {
      msg: '提现密码不可与登录密码相同',
      lang: 'match.word38',
    },
    {
      msg: '该玩家禁止提现',
      lang: 'match.word39',
    },
    {
      msg: '条件不满足',
      lang: 'match.word40',
    },
    {
      msg: '密码位数不能低于6位',
      lang: 'match.word41',
    },
    {
      msg: '密码需包含大写字母',
      lang: 'match.word43',
    },
    {
      msg: '密码需包含小写字母',
      lang: 'match.word44',
    },
    {
      msg: '密码需包含数字',
      lang: 'match.word45',
    },
    {
      msg: '不符合领取条件',
      lang: 'match.word46',
    },
    {
      msg: '下注高限额',
      lang: 'match.word47',
    },
    {
      msg: '请求失败,请稍后再试',
      lang: 'match.word48',
    },
    {
      msg: '您所在的地区无法进入游戏',
      lang: 'match.word49',
    },
    {
      msg: '请输入提款密码',
      lang: 'match.word50',
    },
    {
      msg: '邮箱格式不正确',
      lang: 'match.word51',
    },
    {
      msg: '手机号不正确',
      lang: 'match.word52',
    },
    {
      msg: '手机号格式不正确',
      lang: 'match.word53',
    },
    {
      msg: '领取佣金不可小于5U',
      lang: 'commission.word33',
    },
    {
      msg: '最多只能生成20个邀请码',
      lang: 'commission.word34',
    },
    {
      msg: '参数错误',
      lang: 'common.limit',
    },
    {
      msg: '您的区域暂不支持',
      lang: 'common.notSupper',
    },
    {
      msg: '邮箱已被占用',
      lang: 'common.bindEamil',
    },
    {
      msg: '渠道不正确',
      lang: 'match.word56',
    },
    {
      msg: '下注低限额',
      lang: 'match.word57',
    },
    {
      msg: 'Telegram超过长度限制',
      lang: 'match.word58',
    },
    {
      msg: '系统错误,请稍后再试',
      lang: 'match.word59',
    },
    {
      msg: '账号错误',
      lang: 'match.word60',
    },
    {
      msg: '邀请码不存在',
      lang: 'match.word61',
    },
    {
      msg: '运营商不存在',
      lang: 'match.word62',
    },
    {
      msg: '运营商已被禁用',
      lang: 'match.word63',
    },
    {
      msg: '代理权限不足',
      lang: 'match.word64',
    },
    {
      msg: '验证码已过期',
      lang: 'match.word65',
    },
    {
      msg: '钱包地址已存在',
      lang: 'match.word66',
    },
    {
      msg: '大于最高金额',
      lang: 'match.word67',
    },
    {
      msg: '分成不可大于自己的分成',
      lang: 'match.word68',
    },
    {
      msg: '您还没有任何信息，请联系客服处理',
      lang: 'login.retrieve.word8',
    },
    {
      msg: '每日02:00:00以后才可领取佣金',
      lang: 'match.word69',
    },
    {
      msg: '活动配置有误',
      lang: 'match.word70',
    },
    {
      msg: '该玩家禁止领取',
      lang: 'match.word71',
    },
    {
      msg: '已过了领取时间',
      lang: 'match.word72',
    },
    {
      msg: '地址已存在，请更换地址',
      lang: 'match.word73',
    },
  ]
  const filter = errmsgList.filter((item) => item.msg === errmsg)
  return filter[0]?.lang
}
