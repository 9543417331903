import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import userStore from "./user";
import gameStore from "./game";

Vue.use(Vuex);
const files = require.context("./modules", false, /\.js$/);
const modules = files
  .keys()
  .reduce(
    (p, c) => ({ ...p, [c.replace(/(\.\/|\.js)/g, "")]: files(c).default }),
    {}
  );

export default new Vuex.Store({
  state: {
    direction: "forward", // 页面切换方向
    isLogin: false,
    isLoading: false,
    isMobile: true,
    Lang: localStorage.getItem("lang") || window.navigator.language.slice(0, 2),
    Language:
      window.navigator.language == "zh-TW" ||
      window.navigator.language == "zh-HK"
        ? "zh-TW"
        : window.navigator.language.slice(0, 2),
    GameType: "", // 首页游戏大类
    OpenChatRoom: false, // 聊天室
    isChatRoomBlack: false, //聊天室黑名单
    ShowAnnmt: false, // 公告弹窗
    ShowGift: false, // 新人礼金弹窗
    ShowTgGift: false, // TG登录新人礼金弹窗
    dot: false, // 消息绿点
    showMenuView: false, // 菜单
    showLangBar: false, // 语言弹窗
    historyCount: 0,
    mainNavIndex: 0, // 头部导航栏
    betChipAmount: 0, //下注筹码金额
    betChipIndex: 0, //选择筹码idx
    nextBlock: 0, //即将开奖区块
    curTotleBet: localStorage.getItem("curTotleBet") || 0,
    iFrameUrls: {}, //内嵌iFrameUrl
    tradingUrl: "", //秒合约url
    upDownUrl: "", //涨跌url
  },
  getters: {},
  mutations: {
    // 更新页面切换方向
    updateDirection(state, direction) {
      state.direction = direction;
    },
    updateIsLogin(state, data) {
      state.isLogin = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setIsMobile(state, data) {
      state.isMobile = data;
    },
    setLang(state, data) {
      state.Lang = data;
    },
    setGameType(state, data) {
      state.GameType = data;
    },
    setOpenChatRoom(state, data) {
      state.OpenChatRoom = data;
    },
    setChatRoomBlack(state, data) {
      state.isChatRoomBlack = data;
    },
    setShowAnnmt(state, data) {
      state.ShowAnnmt = data;
    },
    setShowGift(state, data) {
      state.ShowGift = data;
    },
    setShowTgGift(state, data) {
      state.ShowTgGift = data;
    },
    setDot(state, data) {
      state.dot = data;
    },
    setMenuView(state, data) {
      state.showMenuView = data;
    },
    setLangBar(state, data) {
      state.showLangBar = data;
    },
    setHistoryCount(state, data) {
      state.historyCount = data;
    },
    setMainNavIndex(state, data) {
      state.mainNavIndex = data;
    },
    setBetChipAmount(state, data) {
      state.betChipAmount = data;
    },
    setBetChipIndex(state, data) {
      state.betChipIndex = data;
    },
    setNextBlock(state, data) {
      state.nextBlock = data;
    },
    setCurTotleBet(state, data) {
      state.curTotleBet = data;
    },
    setIFrameUrls(state, data) {
      state.iFrameUrls = data;
    },
    setTradingUrl(state, data) {
      state.tradingUrl = data;
    },
    setUpDownUrl(state, data) {
      state.upDownUrl = data;
    },
  },
  modules: {
    ...modules,
    userStore,
    gameStore,
  },
  plugins: [
    createPersistedState({
      key: "haxi_store",
      paths: ["userStore", "gameStore"],
    }),
  ],
});
