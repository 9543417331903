import { post, service } from '@/core/request'

// 获取客户列表
export const rewardPool = (params) => post(`${service.game}/reward_pool`, params, { loading: false })

// 游戏列表
export const gameList = (params) => post(`${service.game}/list`, params)

// 转账游戏路子
export const transferGameLuzi = (params) => post(`luzi/list`, params, { loading: false })

// 1分哈希下期开奖
export const lotteryNext = (params) => post(`lottery/next`, params, { loading: false })

// 1分哈希查询开奖
export const lotteryQuery = (params) => post(`lottery/query`, params, { loading: false })

// 1分哈历史开奖
export const openHistory = (params) => post(`lottery/open_history`, params, { loading: false })

// 游戏下注
export const lotteryBet = (params) => post(`lottery/bet`, params, { loading: false })

// 余额玩法投注记录
export const pageBetRecorde = (params) => post(`${service.game}/bet_recordex`, params, { loading: false })

// 投注记录游戏大类数据
export const recordAbstract = (params) => post(`${service.game}/record_abstract`, params, { loading: false })

// 投注记录
export const betRecord = (params) => post(`${service.game}/bet_record`, params, { loading: false })

// 电子游戏大厅列表
export const gameLobbyList = (params) => post(`${service.game}/gamelist`, params, { loading: false })

// 获取哈希下注信息
export const getHashBetInfo = (params) => post(`${service.lottery}/get_hash_bet_info`, params, { loading: false })

// 自定义筹码
export const getChipsList = (params) => post(`${service.lottery}/get_config`, params, { loading: false })

// 转账玩法下注记录
export const transferRecord = (params) => post(`${service.game}/transfer_record`, params, { loading: false })

// 转账玩法更新下注记录
export const newTransferRecord = (params) => post(`${service.game}/new_transfer_record`, params, { loading: false })

// 最近盈利
export const recentProfits = (params) => post(`${service.game}/recentProfit`, params, { loading: false })
