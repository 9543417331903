import api from '@/api/old_client_index'

import utils from '@/utils'
const state = {
  SwiperList: [],
  gameList: [],
  Queuedmsg: [],
  rewardPool: [],
  gameInfo: {},
}
const mutations = {
  setQueuedmsg(state, data) {
    state.Queuedmsg = data;
  },
  setGameList(state, data) {
    state.gameList = data;
  },
  setRewardPool(state, data) {
    state.rewardPool = data;
  },
  setGameInfo(state, data) {
    state.gameInfo = data
  },
  setSwiperList(state, data) {
    state.SwiperList = data
  },
}
const actions = {
  async getSwiperList({ commit }, data) { // 获取轮播图
    const res = await api.active.homeCarouselList(data)
    commit('setSwiperList', res.data.data)
  },
  async getQueuedmsg({ commit }, data) { // 获取跑马灯
    const res = await api.active.marquee(data)
    commit('setQueuedmsg', res.data)
  },
  async getGameList({ commit }, GameId) { // 获取游戏列表
    const res = await api.game.gameList()
    commit('setGameList', res.data)
    // let arr = res.data.find(item => item.GameId == GameId);
    // commit('setGameInfo', arr)
  },
  async getRewardPool({ commit }) { // 奖池信息
    const res = await api.game.rewardPool()
    let data = res.data
    let addressList = Object.keys(data)
      .filter(key => key.startsWith('Address'))
      .map(key => {
        const index = key.split('Address')[1];
        if (data[key] === '0') {
          return null; // 跳过该项
        }
        return {
          Address: data[key],
          Trx: data[`Trx${index}`].toFixed(2),
          Usdt: data[`Usdt${index}`].toFixed(2),
        };
      }).filter(item => item !== null);
    commit('setRewardPool', addressList)
  },
  async getRankData({ }, data) { // 获取奖池信息
    const res = await api.app.rankData(data)
    if (res.code === 200) {
        return Promise.resolve(res.data)
    } else {
        return Promise.reject(res.msg)
    }
  },
}
const getters = {
  rewardPoolInfo(state) {
    let data = state.rewardPool
    let info = {
      totalTrx: '',
      totalUsdt: '',
      mainAddress: ''
    }
    info.mainAddress = data[0]?.Address
    info.totalTrx = data.map((row) => row.Trx).reduce((acc, cur) => utils.add(cur, acc), 0, 2)
		info.totalUsdt = data.map((row) => row.Usdt).reduce((acc, cur) => utils.add(cur, acc), 0, 2)
    return info
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
