// src/components/SvgIcon.vue

<!-- 公共svgicon组件 -->
<template>
  <svg :class="svgClass" aria-hidden="true" :width="width" :height="height">
    <use :xlink:href="iconName"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '20px'
    },
    height: {
      type: String,
      default: '20px'
    }
  },
  computed: {
    iconName () {
      return `#icon-${this.iconClass}`
    },
    svgClass () {
      if (this.className) {
        return `za-svg-icon icon-${this.iconClass} ` + this.className
      } else {
        return `za-svg-icon icon-${this.iconClass}`
      }
    }
  }
}
</script>

<style lang="scss">
.za-svg-icon {
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
}
</style>
