// 导入日期过滤器
import * as dateFilters from './modules/DateFilter';

// 导入文本过滤器
import * as textFilters from './modules/TextFilter';

// 导出一个函数，该函数接收 Vue 实例作为参数
export default function install(Vue) {
  // 注册日期过滤器
  Object.keys(dateFilters).forEach(key => {
    Vue.filter(key, dateFilters[key]);
  });

  // 注册文本过滤器
  Object.keys(textFilters).forEach(key => {
    Vue.filter(key, textFilters[key]);
  });
}
