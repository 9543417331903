import api from '@/api/old_client_index'

import utils from '@/utils'
const state = {
  SymbolList: [],
  ChannelList: [],
  europeanList: [],
}
const mutations = {
  setSymbolList(state, data) {
    state.SymbolList = data
  },

  setChannelList(state, data) {
    state.ChannelList = data
  },

  setEuropeanList(state, data) {
    state.europeanList = data
  },
}
const actions = {
  async getThirdSign({}, data) {
    try {
      let obj = utils.thirdParams(data.id, data.type)
      let type = data.type
      if (type.indexOf('hub88') != -1) {
        type = 'hub88'
      }
      const res = await api.third[`${type}Login`](obj)
      if (res.code === 200) {
        return Promise.resolve(res.data)
      } else {
        return Promise.reject(res.msg)
      }
    } catch (error) {
      throw error
    }
  },
  async getSyncAmount({}) {
    const res = await api.third.syncAmount()
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getSymbolList({ commit, rootState }) {
    // 获取三方支付币种列表
    const res = await api.third.symbolList()
    res.data.forEach((item) => {
      item.icon = rootState.app.appInfo.ImageUrl + item.Icon
      item.label = item.Symbol.toUpperCase()
    })
    commit('setSymbolList', res.data)
  },
  async getChannelList({}, data) {
    // 获取三方支付币种列表
    const res = await api.third.channelList(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getCreateOrder({}, data) {
    const res = await api.third.createOrder(data)
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getGetList({}) {
    const res = await api.third.GetList()
    if (res.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.msg)
    }
  },
  async getEuropeanList({ commit, rootState }) {
    // 获取三方体育赛事
    const res = await api.third.getEasybetspHotEvents()
    let data = res.data.data.filter((item, index) => {
      if (item.competition_name == 'UEFA European Championship' && item.matches && item.matches.length) {
        //'UEFA European Championship' 'Egypt Premier League'
        for (let i = 0; i < item.matches.length; i++) {
          let matches = item.matches[i]
          if (
            matches &&
            matches.market &&
            matches.market[0] &&
            matches.market[0].fix_markets.ASIAN_HANDICAP &&
            matches.market[0].fix_markets.ASIAN_HANDICAP.selections
          ) {
            let selections = matches.market[0].fix_markets.ASIAN_HANDICAP.selections
            let homeSelection = selections.find((value, index) => {
              return value.type == 'Home'
            })
            let awaySelection = selections.find((value, index) => {
              return value.type == 'Away'
            })
            if (homeSelection && awaySelection) {
              return true
            }
          }
        }
      }
      return false
    })
    commit('setEuropeanList', data)
  },
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
