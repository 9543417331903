import { post, service } from '@/core/request'

// 用户登录注册
export const login = (params) => post(`${service.user}/login`, params, { loading: false })
export const regist = (params) => post(`${service.user}/register`, params, { loading: false })
export const logout = (params) => post(`${service.user}/logout`, params, { loading: false })
export const userInfo = (params, pLoading = true) => post(`${service.user}/info`, params, { loading: pLoading })
export const customService = (params) => post(`${service.user}/customservice`, params) // 客服链接
export const getBalance = (params) => post(`${service.user}/get_balance`, params, { loading: false }) // 刷新余额
export const getWalletAddress = (params) => post(`${service.user}/get_wallet_address`, params, { loading: false }) // 获取钱包地址
export const addWalletAddress = (params) => post(`${service.user}/add_wallet_address`, params, { loading: false }) // 添加钱包地址
export const withdraw = (params) => post(`${service.user}/withdraw_v2`, params, { loading: false }) // 提现
export const vipInfo = (params) => post(`vip/vip_info`, params) // vip列表
export const winLossReport = (params) => post(`rec/winloss_report`, params, { loading: false }) // 输赢报表
export const recRecharge = (params) => post(`rec/recharge`, params, { loading: false }) // 交易记录-充值
export const recWithdraw = (params) => post(`rec/withdraw`, params, { loading: false }) // 交易记录-提现
export const recTransfer = (params) => post(`rec/transfer`, params, { loading: false }) // 交易记录-转账
export const reCaijin = (params) => post(`rec/caijin`, params, { loading: false }) // 交易记录-红利
export const TrialFee = (params) => post(`${service.user}/tiyanjing`, params) // 领取体验金
export const gamePeilv = (params) => post(`${service.user}/game_peilv`, params) // 个人赔率
export const setUserInfo = (params) => post(`${service.user}/set_info`, params) // 个人信息设置
export const modLoginPwd = (params) => post(`${service.user}/set_login_password`, params) // 修改登录密码
export const setFundPwd = (params) => post(`${service.user}/set_wallet_password`, params) // 设置资金密码
export const bindPhone = (params) => post(`${service.user}/bind_phone`, params) // 手机号绑定
export const addBankCard = (params) => post(`${service.user}/add_bank`, params)
export const getBankCardList = (params) => post(`${service.user}/get_bank`, params)
export const withdrawAddr = (params) => post(`${service.user}/get_withdraw_address`, params, { loading: false }) // 提现地址列表
export const addWithdrawAddr = (params) => post(`${service.user}/add_withdraw_address`, params, { loading: false }) // 增加提现地址
export const delWithdrawAddr = (params) => post(`${service.user}/delete_wallet_address`, params) // 删除提现地址
// 获取充值地址
export const address = (params) => post(`${service.user}/get_recharge_address`, params, { loading: false })

// 修改用户自定义筹码
export const changeUserChips = (params) => post(`${service.user}/update_game_chip`, params, { loading: false })
// TG绑定
export const sendBind = (params) => post(`${service.user}/tg_sendBind`, params, { loading: false })
//找回密码-验证账号
export const checkUserEmail = (params) => post(`${service.user}/check_user_email`, params, { loading: false })
//找回密码
export const retrievePassword = (params) => post(`${service.user}/retrieve_password`, params, { loading: false })
